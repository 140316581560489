<template lang="pug">
#UiModales.posf.b0.UiModales(:style="ruidoDataURL ? `--ruido: ${ruidoDataURL}` : ''")
</template>

<script lang="ts" setup>
import { getActiveHead } from 'unhead'

const baseFrequency = ref(.55)
const ruido = ref<Node>()

// const tamanoRuido = 3000

const ruidoDataURL = computed(() => {
	const svgRef = unref(ruido)
	const f = unref(baseFrequency)
	if (!svgRef || !f) {
		// console.error('UiModales > ruidoDataURL > !svgRef')
		return ''
	}
	console.info('UiModales > ruidoDataURL > svgRef')

	const svgDOM = document.getElementById('ruido')!
	if (!svgDOM) {
		console.error('UiModales > ruidoDataURL > !svgDOM')
		return ''
	}
	console.info('UiModales > ruidoDataURL > svgDOM')

	// const svgString = serializer.serializeToString(unref(ruido))
	const serializer = new XMLSerializer()
	const svgString = serializer.serializeToString(svgDOM)
	const encodedSvg = encodeURIComponent(svgString)
		.replace(/'/g, '%27')
		.replace(/"/g, '%22')
	const dataUrl = `url("data:image/svg+xml,${encodedSvg}")`
	return dataUrl
})


// Referencia para almacenar el número de elementos dentro de #UiModales
const modalesPreparadosCantidad = ref<number>(0)
const modalesAbiertosCantidad = ref<number>(0)

// Función para actualizar el número de elementos cuando se insertan nuevos elementos dentro de #UiModales

function actualizarmodalesPreparadosCantidad() {
	const UiModales = document.getElementById('UiModales')
	if (UiModales) {
		// Actualizar la referencia con el número de elementos dentro de #UiModales
		modalesPreparadosCantidad.value = UiModales.children.length
		const modales = UiModales.querySelectorAll('.elModal-nucleo')
		modalesAbiertosCantidad.value = modales.length
	}
}

// Usar el hook onMounted para observar los cambios después de la renderización inicial
onMounted(() => {
	// Observar los cambios en el DOM usando MutationObserver
	const observer = new MutationObserver(actualizarmodalesPreparadosCantidad)

	// Configurar el observador para observar cambios en #UiModales y sus hijos
	observer.observe(document.getElementById('UiModales')!, { childList: true, subtree: true })

	// Actualizar el número de elementos inicialmente
	actualizarmodalesPreparadosCantidad()
})

watchEffect(() => {
	const abiertos = unref(modalesAbiertosCantidad)
	// console.log('watchEffect', 'abiertos', abiertos)
	const headActivo = getActiveHead()
	if (!headActivo)
		return

	headActivo.push({
		bodyAttrs: {
			tagDuplicateStrategy: 'replace',
			class: abiertos ? 'afectadoPorModal' : ''
		}
	}, {})
})

</script>

<style lang="sass">
@import '@base/sass/comun'

.UiModales
	z-index: 45
	// --ruido: v-bind(ruidoDataURL)

</style>
