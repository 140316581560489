import { consolo } from '../composables/consolo'

type Funcion = (...args: any[]) => void
const EmisorEventosObjeto: {
	_events: Record<string, Funcion[]>
	on: (evento: string, callback: Funcion) => void
	once: (evento: string, callback: Funcion) => void
	off: (evento: string, callback: Funcion) => void
	emit: (evento: string, ...args: any[]) => void
} = {
	_events: {},
	on(name: string, listener: Funcion) {
		if (!this._events[name])
			this._events[name] = []
		this._events[name].push(listener)
	},
	once(name: string, listener: Funcion) {
		const onceWrapper = (...args: any[]) => {
			listener(...args)
			this.off(name, onceWrapper)
		}
		this.on(name, onceWrapper)
	},

	off(name: string, listenerToRemove: Funcion) {
		if (!this._events[name]) {
			consolo.warn(`Evento "${name}" no existe.`)
			return
		}
		const filterListeners = (l: Funcion) => l !== listenerToRemove
		this._events[name] = this._events[name].filter(filterListeners)
	},

	emit(name: string, data: any) {
		if (!this._events[name]) {
			consolo.info(`Evento "${name}", no tiene suscritos.`)
			return
		}
		this._events[name].forEach((callback: Funcion) => {
			callback(data)
		})
	}
}

export default EmisorEventosObjeto
