import { ContextoAppZod } from "../../../comun/estructuraContextoApp"
import contextoAppJson from '../assets/contextoApp.json'

const contextoAppParseo = ContextoAppZod.safeParse(contextoAppJson)

if (!contextoAppParseo.success) {
	console.error('Error al parsear contextoApp.json')
	throw new Error('Error al parsear contextoApp.json')
}

export const contextoApp = contextoAppParseo.data

export const esAppGold = !['boxmagic', 'members'].includes(contextoApp.appConfig.appID)