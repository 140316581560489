import {
	FrontRosetta,
	elegirIdiomaVisible,
	idiomaVisible,
	idiomasDisponibles
} from '@base/lib/rosetta'


export const TrosettaAPI = {
	crear: FrontRosetta,
	elegirIdiomaVisible: elegirIdiomaVisible,
	idiomaVisible,
	idiomasDisponibles
}
