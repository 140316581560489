

export default defineNuxtPlugin((nuxtApp) => {
	// * Sólo en cliente
	if (!import.meta.client) return

	nuxtApp.hook('app:mounted', () => {
		console.log('\non app:mounted')

		const windowResize = _.debounce(() => {
			emitirEventoDeApp('windowResize')
		}, 500)
		window.addEventListener('resize', windowResize)
		TemaOscuroAPI.propagarTema()
	})

	// * Detectar cambios de tema configurados en la página
	nuxtApp.hook('app:suspense:resolve', () => {
		console.log('\non app:suspense:resolve')
		refrescarTemaDeRuta()
	})

	nuxtApp.hook('page:loading:end', () => {
		console.log('\non page:loading:end')
		TemaOscuroAPI.propagarTema()
		refrescarTemaDeRuta()
	})
})
