import localforage from 'localforage'

type subconjuntoAPI = Pick<LocalForage, 'getItem' |
			'setItem' |
			'removeItem' |
			'clear'>

export function crearStorage(nombre: string): subconjuntoAPI {
	if (typeof window === 'undefined') {
		// Devolver un objeto similar a LocalForage
		const falsa: subconjuntoAPI = {
			getItem: async () => null,
			setItem: async (_k, v) => v,
			removeItem: async (_k) => undefined,
			clear: async () => undefined
		}
		return falsa
	}
	const instancia = localforage.createInstance({ name: nombre });
	return instancia;
}
