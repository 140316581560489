import _ from 'lodash'
import { z } from 'zod'
import { ConformarIntanciaID } from '@comun/instancias'
import { esRolStaff, GimnasioListadoZod, GimnasioZod, PerfilEnGimnasioZod } from '@comun/types'


import { ErrorAumentado } from '@base/lib/error'
import { ErrorNotificableZod, i18nIconos, i18nTapi } from '@base/lib/erroresNotificables'

import type { GimnasioListado, PerfilEnGimnasio, PerfilEnGimnasioConRelacionados, RegistroDeGimnasiosListados, RegistroDePerfilesEnGimnasio, Reserva } from '@comun/types'

export function verificarSiEsAlumno(perfilEnGimnasio: PerfilEnGimnasio) {
	const permisoPara = perfilEnGimnasio.permisos
	if (permisoPara.reservar)
		return true
	if (permisoPara.cancelarReserva)
		return true
	return false
}

export function verificarSiEsStaff(perfilEnGimnasio: PerfilEnGimnasio) {
	return lodash.some(perfilEnGimnasio.roles, rol => esRolStaff(rol))
}


// * Storage

export const roles = computed(() => {
	const peg = unref(usePerfilEnGimnasio().perfilEnGimnasio)
	if (!peg)
		return []
	return peg.roles
})

const perfilesEnGimnasiosBrutosRef = ref<RegistroDePerfilesEnGimnasio | null>(null)

export const perfilesEnGimnasios = computed(() => {
	const pegs = unref(perfilesEnGimnasiosBrutosRef)
	if (!pegs && typeof pegs === 'object')
		return null
	return pegs
})

export const perfilesEnGimnasiosVigentes = computed(() => {
	const pegs = unref(perfilesEnGimnasios)
	if (!pegs)
		return {}
	const vigentes = lodash.pickBy(pegs, peg => {
		if (!peg.membresias || lodash.isEmpty(peg.membresias)) return false
		const membresiasVigentes = filtrarMembresiasVigentes(peg.membresias)
		return !lodash.isEmpty(membresiasVigentes)
	})
	return vigentes
})

export const perfilEnGimnasio = computed((): PerfilEnGimnasioConRelacionados | null | false => {
	const pegs = unref(perfilesEnGimnasios)
	if (!pegs && typeof pegs === 'object')
		return null

	if (lodash.isEmpty(pegs))
		return false

	const { gimnasio: gimRef, gimnasioID: gimRefID } = useGimnasio()
	const gimnasio = unref(gimRef)
	const gimnasioID = unref(gimRefID)
	if (!gimnasio)
		return false
	if (!gimnasioID)
		return false
	if (gimnasio.gimnasioID !== gimnasioID)
		return false

	const perfilEnGim = pegs[gimnasioID]
	if (!perfilEnGim)
		return false

	const { membresias, reservas } = RelacionarMembresiasYReservas({
		gimnasio,
		perfilEnGimnasio: perfilEnGim,
		origen: 'Tapi>PerfilEnGimnasioComputed'
	})

	const perfilEnGimnasioConRelacionados: PerfilEnGimnasioConRelacionados = {
		...perfilEnGim,
		membresiasConRelacionados: membresias,
		reservasConRelacionados: reservas
	}
	return perfilEnGimnasioConRelacionados
})

export const membresias = computed(() => {
	const peg = unref(perfilEnGimnasio)
	if (!peg)
		return {}
	return peg.membresiasConRelacionados
})

export const membresiasVigentes = computed(() => {
	const membresiasTodas = unref(membresias)
	if (!membresiasTodas)
		return {}
	const membresiasFiltradas = filtrarMembresiasVigentes(membresiasTodas)
	return membresiasFiltradas
})

export const esMiembroExclusivo = computed((): boolean => {
	const vigentes = unref(membresiasVigentes)
	if (!vigentes)
		return false

	const exclusivas = lodash.pickBy(vigentes, (membresia) => {
		const plan = membresia.plan
		if (!plan)
			return false
		return plan.soloParaMiembrosExclusivos
	})

	return !lodash.isEmpty(exclusivas)
})

export const reservas = computed(() => {
	const peg = unref(perfilEnGimnasio)
	if (!peg)
		return null
	return peg.reservasConRelacionados
})

export const reservasFuturas = computed(() => {
	const relo = unref(reloj)
	const rs = unref(reservas)
	if (lodash.isEmpty(rs))
		return {}
	if (!relo)
		return {}

	const reservasFuturas: Record<string, Reserva> = lodash.pickBy(rs, r => relo.isBefore(r.fechaInicio))
	return reservasFuturas
})

export const proximaReserva = computed(() => {
	const proximaReserva = lodash.first(lodash.orderBy(unref(reservasFuturas), r => r.fechaInicio, 'asc'))
	return proximaReserva ?? false
})

export const esAlumno = computed(() => {
	const peg = unref(perfilEnGimnasio)
	if (!peg)
		return false
	return verificarSiEsAlumno(peg)
})

export const esStaff = computed(() => {
	const peg = unref(perfilEnGimnasio)
	if (!peg)
		return false
	return verificarSiEsStaff(peg)
})


// watch(membresias, ProgramarAvisosDePago, { immediate: true })

function IntegrarPerfilEnGimnasio(pegRecibido: PerfilEnGimnasio) {
	const reservas: Record<string, Reserva> = {}
	for (const reservaID in pegRecibido.reservas) {
		const reserva = pegRecibido.reservas[reservaID]
		const { fechaYMD, claseID, horarioID } = reserva
		const instanciaID = ConformarIntanciaID({ fechaYMD, claseID, horarioID })


		reservas[reserva.reservaID] = {
			...reserva,
			instanciaID
		}
	}

	const pegProcesado = {
		...pegRecibido,
		reservas
	}

	const pegs = unref(perfilesEnGimnasiosBrutosRef) ?? {}
	const registroActualizado = Object.assign({}, pegs, { [pegProcesado.gimnasioID]: pegProcesado })
	perfilesEnGimnasiosBrutosRef.value = registroActualizado

	return pegProcesado
}

let RefrescandoPerfilEnGimnasioPromise: Promise<PerfilEnGimnasio | false> | null = null
const refrescandoPerfilEnGimnasioRef = ref(false)
export const refrescandoPerfilEnGimnasioPropio = computed(() => unref(refrescandoPerfilEnGimnasioRef))

async function ObtenerPerfilEnGimnasio(): Promise<PerfilEnGimnasio | false> {
	const fx = 'ObtenerPerfilEnGimnasio'
	try {
		if (RefrescandoPerfilEnGimnasioPromise) {
			consolo.info(fx, 'ya se está cargando')
			return RefrescandoPerfilEnGimnasioPromise
		}
		refrescandoPerfilEnGimnasioRef.value = true
		RefrescandoPerfilEnGimnasioPromise = ObtenerPerfilEnGimnasioFx()
		// Esperar a que la operación pesada se complete y devolver el resultado
		const result: PerfilEnGimnasio | false = await RefrescandoPerfilEnGimnasioPromise
		return result
	}
	catch (e) {
		if (e instanceof ErrorAumentado)
			throw e.trazar(fx)
		consolo.error('error', e)
		throw new ErrorAumentado(fx, { error: e })
	}
	finally {
		RefrescandoPerfilEnGimnasioPromise = null
		refrescandoPerfilEnGimnasioRef.value = false
	}
}

async function ObtenerPerfilEnGimnasioFx(): Promise<PerfilEnGimnasio | false> {
	const fx = 'ObtenerPerfilEnGimnasioFx'
	consolo.log(fx)
	const timerID = await MiniID()
	consolo.time(timerID)
	try {
		const gimnasioID = unref(useGimnasio().gimnasioID)
		if (!gimnasioID) {
			consolo.warn(fx, 'no hay gimnasioID', gimnasioID)
			return false
		}

		const respuesta = await axiosWorker({
			url: `${contextoApp.buildConfig.apiUrl}/boxmagic/perfilEnGimnasio`,
			method: 'get',
			headers: HeadersConAuth()
		})
			

		// consolo.log('respuesta', respuesta)

		const parseado = z
			.discriminatedUnion('ok', [
				z.object({
					ok: z.literal(true),
					perfilEnGimnasio: PerfilEnGimnasioZod,
					gimnasio: GimnasioZod.optional()
				}),
				z.object({
					ok: z.literal(false),
					perfilEnGimnasio: z.literal(false).optional(),
					error: ErrorNotificableZod
				})
			])
			.safeParse(respuesta)

		if (!parseado.success)
			throw new ErrorAumentado('malParseo', { origen: fx, error: parseado.error })

		const resultado = parseado.data
		if (!resultado.ok) {
			const errorID = resultado.error

			if (errorID === 'sinPerfilEnGimnasio') {
				const i18n = TrosettaAPI.crear({
					noFueEncontrado: {
						es: 'No se encontró un perfil en el gimnasio',
						en: 'No profile in the gym was found',
						pt: 'Não foi encontrado um perfil na academia'
					}
				})
				notificadorEnApp.atencion({
					titulo: i18nTapi('sinPerfilEnGimnasio'),
					texto: i18n('noFueEncontrado'),
					codigo: errorID,
					icono: i18nIconos[errorID]
				})

				const pegs = unref(perfilesEnGimnasiosBrutosRef) ?? {}
				const registroActualizado = Object.assign({}, pegs, { [gimnasioID]: false })
				perfilesEnGimnasiosBrutosRef.value = registroActualizado

				await new Promise(requestAnimationFrame)
				await nextTick()
				return false
			}
			else {
				notificadorEnApp.atencion({
					titulo: i18nTapi('falloObtencionDePerfilEnGimnasio'),
					texto: i18nTapi(errorID),
					codigo: errorID,
					icono: i18nIconos[errorID]
				})

				consolo.log('error', resultado.error, resultado)
				perfilesEnGimnasiosBrutosRef.value = Object.assign({}, perfilesEnGimnasiosBrutosRef.value || {}, { [gimnasioID]: null })
				// if (!perfilEnGimnasio.value) await perfilEnGimnasioStorage.clear()

				return false
			}
		}

		const pegRecibido = resultado.perfilEnGimnasio
		if (resultado.gimnasio) GimnasioAPI.IntegrarGimnasio(resultado.gimnasio)

		if (!pegRecibido)
			return false

		const reservas: Record<string, Reserva> = {}
		for (const reservaID in pegRecibido.reservas) {
			const reserva = pegRecibido.reservas[reservaID]
			const { fechaYMD, claseID, horarioID } = reserva
			const instanciaID = ConformarIntanciaID({ fechaYMD, claseID, horarioID })


			reservas[reserva.reservaID] = {
				...reserva,
				instanciaID
			}
		}

		const pegProcesado = {
			...pegRecibido,
			reservas
		}

		const pegs = unref(perfilesEnGimnasiosBrutosRef) ?? {}
		const registroActualizado = Object.assign({}, pegs, { [pegProcesado.gimnasioID]: pegProcesado })
		perfilesEnGimnasiosBrutosRef.value = registroActualizado

		await new Promise(requestAnimationFrame)
		await nextTick()


		emitirEventoDeApp('perfilesEnGimnasio', registroActualizado)

		return pegProcesado
	}
	catch (e) {
		if (e instanceof ErrorAumentado)
			throw e.trazar(fx)
		consolo.error('error', e)
		throw new ErrorAumentado(fx, { error: e })
	}
	finally {
		consolo.timeEnd(timerID)
		consolo.log(fx)
	}
}

type PYGs = {
	perfilesEnGimnasios: RegistroDePerfilesEnGimnasio
	gimnasios: RegistroDeGimnasiosListados
}

let RefrescandoPerfilesEnGimnasiosPromise: Promise<PYGs | false> | null = null
const RefrescandoPerfilesEnGimnasiosRef = ref(false)
export const RefrescandoPerfilesEnGimnasios = computed(() => unref(RefrescandoPerfilesEnGimnasiosRef))

async function ObtenerVinculadosFx(): Promise<PYGs | false> {
	const fx = 'ObtenerVinculadosFx'
	consolo.log(fx)
	const timerID = await MiniID()
	consolo.time(timerID)
	try {
		const headers = HeadersConAuth()
		const respuesta = await axiosWorker({
			url: `${contextoApp.buildConfig.apiUrl}/boxmagic/perfilEnGimnasio/vinculados`,
			method: 'get',
			headers
		})
			
		// consolo.log('respuesta', respuesta)
		const parseado = z
			.discriminatedUnion('ok', [
				z.object({
					ok: z.literal(true),
					perfilesEnGimnasios: z.array(PerfilEnGimnasioZod),
					gimnasios: z.array(GimnasioListadoZod)
				}),
				z.object({
					ok: z.literal(false),
					error: ErrorNotificableZod
				})
			])
			.safeParse(respuesta)

		if (!parseado.success)
			throw new ErrorAumentado('malParseo', { origen: fx, error: parseado.error })

		const resultado = parseado.data
		if (!resultado.ok) {
			consolo.error('error', resultado.error)
			perfilesEnGimnasiosBrutosRef.value = null
			throw new ErrorAumentado('error', { error: resultado.error })
		}
		// consolo.log('resultado', resultado)

		const registroDePerfiles: Record<string, PerfilEnGimnasio> = {}
		for (const perfilEnGimnasio of resultado.perfilesEnGimnasios) {
			// if (!registroDePerfiles[perfilEnGimnasio.gimnasioID]) registroDePerfiles[perfilEnGimnasio.gimnasioID] = []
			registroDePerfiles[perfilEnGimnasio.gimnasioID] = perfilEnGimnasio
		}

		perfilesEnGimnasiosBrutosRef.value = registroDePerfiles

		const registroDeGimnasiosListados: Record<string, GimnasioListado> = {}
		for (const gimnasio of resultado.gimnasios)
			registroDeGimnasiosListados[gimnasio.gimnasioID] = gimnasio

		integrarGimnasiosDesdeVinculados(registroDeGimnasiosListados)

		await new Promise(requestAnimationFrame)

		if (!registroDePerfiles || lodash.isEmpty(registroDePerfiles))
			return false

		emitirEventoDeApp('perfilesEnGimnasio', registroDePerfiles)


		return {
			perfilesEnGimnasios: registroDePerfiles,
			gimnasios: registroDeGimnasiosListados
		}
	}
	catch (e) {
		if (e instanceof ErrorAumentado)
			throw e.trazar(fx)
		consolo.error('error', e)
		throw new ErrorAumentado(fx, { error: e })
	}
	finally {
		consolo.timeEnd(timerID)
		consolo.log(fx)
	}
}

async function ObtenerVinculados(): Promise<PYGs | false> {
	const fx = 'ObtenerVinculados'
	try {
		if (RefrescandoPerfilesEnGimnasiosPromise) {
			consolo.info(fx, 'ya se está cargando')
			return RefrescandoPerfilesEnGimnasiosPromise
		}
		RefrescandoPerfilesEnGimnasiosPromise = ObtenerVinculadosFx()
		RefrescandoPerfilesEnGimnasiosRef.value = true

		// Esperar a que la operación pesada se complete y devolver el resultado
		const result = await RefrescandoPerfilesEnGimnasiosPromise
		// await 50ms
		await new Promise(requestAnimationFrame)


		return result
	}
	catch (e) {
		if (e instanceof ErrorAumentado)
			throw e.trazar(fx)
		consolo.error('error', e)
		throw new ErrorAumentado(fx, { error: e })
	}
	finally {
		RefrescandoPerfilesEnGimnasiosPromise = null
		RefrescandoPerfilesEnGimnasiosRef.value = false
	}
}

async function AceptarContrato(contrato: string): Promise<boolean> {
	const fx = 'AceptarContrato'
	consolo.log(fx)
	const timerID = await MiniID()
	consolo.time(timerID)
	try {
		const r = await axiosWorker({
			url: `${contextoApp.buildConfig.apiUrl}/boxmagic/perfilEnGimnasio/aceptarContrato`,
			method: 'post',
			headers: HeadersConAuth(),
			data: { contrato }
		})
			
		consolo.log(`${fx} r`, r)
		if (!r.ok)
			throw r.error

		return r.ok
	}
	finally {
		consolo.timeEnd(timerID)
		consolo.log(fx)
	}
}

// configurarNotificaciones

const RespuestaSolicitudCodigoEliminacionZod = z.discriminatedUnion('ok', [
	z.object({
		ok: z.literal(true)
	}),
	z.object({
		ok: z.literal(false),
		error: ErrorNotificableZod
	})
])
type RespuestaSolicitudCodigoEliminacion = z.infer<typeof RespuestaSolicitudCodigoEliminacionZod>

async function SolicitarCodigoEliminacion(pass: string): Promise<RespuestaSolicitudCodigoEliminacion> {
	const fx = 'SolicitarCodigoEliminacion'
	consolo.log(fx)
	const timerID = await MiniID()
	consolo.time(timerID)
	try {
		const respuesta = await axiosWorker({
			url: `${contextoApp.buildConfig.apiUrl}/boxmagic/perfilEnGimnasio/solicitarEliminacion`,
			method: 'post',
			headers: HeadersConAuth(),
			data: {
				pass
			}
		})
			

		const parseoRespuesta = RespuestaSolicitudCodigoEliminacionZod.safeParse(respuesta)
		if (!parseoRespuesta.success) {
			consolo.error('error', parseoRespuesta.error)
			consolo.error('respuesta original', respuesta)
			throw new Error('errorParseado', {
				cause: parseoRespuesta.error
			})
		}
		const respuestaParseada = parseoRespuesta.data

		if (!respuestaParseada.ok) {
			notificadorEnApp.atencion({
				titulo: i18nTapi('falloLaEliminacionDeLaCuenta'),
				texto: i18nTapi(respuestaParseada.error),
				codigo: respuestaParseada.error,
				icono: {
					passwordEquivocado: 'iconoir:password-error'
				}[respuestaParseada.error]
			})
		}

		return respuestaParseada
	}
	finally {
		consolo.timeEnd(timerID)
		consolo.log(fx)
	}
}

const RespuestaConfirmacionEliminacionZod = z.discriminatedUnion('ok', [
	z.object({
		ok: z.literal(true)
	}),
	z.object({
		ok: z.literal(false),
		error: ErrorNotificableZod,
		intentosRestantes: z.number()
	})
])

type RespuestaConfirmacionEliminacion = z.infer<typeof RespuestaConfirmacionEliminacionZod>

async function ConfirmarEliminarPerfil(codigo: string): Promise<RespuestaConfirmacionEliminacion> {
	const fx = 'ConfirmarEliminarPerfil'
	consolo.log(fx)
	const timerID = await MiniID()
	consolo.time(timerID)
	try {
		const respuesta = await axiosWorker({
			url: `${contextoApp.buildConfig.apiUrl}/boxmagic/perfilEnGimnasio`,
			method: 'delete',
			headers: HeadersConAuth(),
			data: {
				codigo
			}
		})
			

		const parseoRespuesta = RespuestaConfirmacionEliminacionZod.safeParse(respuesta)
		if (!parseoRespuesta.success) {
			consolo.error('error', parseoRespuesta.error)
			consolo.error('respuesta original', respuesta)
			throw new Error('errorParseado', {
				cause: parseoRespuesta.error
			})
		}
		const respuestaParseada = parseoRespuesta.data

		if (!respuestaParseada.ok) {
			notificadorEnApp.atencion({
				titulo: i18nTapi('falloLaEliminacionDeLaCuenta'),
				texto: i18nTapi(respuestaParseada.error),
				codigo: respuestaParseada.error,
				icono: {
					passwordEquivocado: 'iconoir:password-error'
				}[respuestaParseada.error]
			})
		}

		return respuestaParseada
	}
	finally {
		consolo.timeEnd(timerID)
		consolo.log(fx)
	}
}

export const PerfilesEnGimnasioAPI = {

	ObtenerVinculados,
	Limpiar() {
		perfilesEnGimnasiosBrutosRef.value = null
	},
}

export const PerfilEnGimnasioAPI = {
	ObtenerPerfilEnGimnasio,
	IntegrarPerfilEnGimnasio,
	AceptarContrato,
	SolicitarCodigoEliminacion,
	ConfirmarEliminarPerfil
}

export function usePerfilEnGimnasio() {
	return {
		perfilEnGimnasio,
		refrescandoPerfilEnGimnasioPropio: refrescandoPerfilEnGimnasioPropio,
		perfilesEnGimnasios,
		RefrescandoPerfilesEnGimnasios,
		membresias,
		membresiasVigentes,
		esMiembroExclusivo,
		reservas,
		esAlumno,
	}
}