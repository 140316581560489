import { ContextoAppZod } from "../../../comun/estructuraContextoApp"
import contextoAppJson from '../assets/contextoApp.json'

const contextoAppParseo = ContextoAppZod.safeParse(contextoAppJson)

if (!contextoAppParseo.success) {
	console.error('Error al parsear contextoApp.json')
	throw new Error('Error al parsear contextoApp.json')
} else {

	// console.log('contextoAppParseo', contextoAppParseo.data)
}

export const contextoApp = contextoAppParseo.data
