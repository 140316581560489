import { z } from 'zod'
import RosettasATraductor from '@base/lib/rosetta'

export {
	IndexZod,
	IDTextualZod,
	IDTextualOpcionalZod,
	IDNumericoOpcionalZod,
	IDNumericoZod,
	NumeroZod,
	NumeroOpcionalZod,
	NumeroONullZod,
	NumeroOCeroZod,
	StringZod,
	StringOpcionalZod,
	StringONull,
	URLZod,
	URLOpcionalZod,
	BooleanOFalsoZod,
	BooleanOVerdaderoZod,
	BooleanOpcionalZod,
	MontoZod,
	MontoPositivoZod,
	MonedasZod,
	PrecioOpcionalZod,
	FechaZod,
	FechaYMDZod,
	FechaYMDOpcional,
	FechaOpcionalZod,
	FechaONullZod,
	FechaOAhoraZod,
	FechaADayjsZod,
	DMYaFechaZod,
	FechaDMYAISOZod,
	FechaADayjsOpcionalZod,
	FechaAISOZod,
	FechaAYMDZod,
	EsFecha,
	HoraZod,
	DiaSemanaZod,
	ArrayCualquieraONada,
	EsArray,
	EmailZod,
	EmailOpcionalZod,
	PassZod,
	PassNuevoZod
} from '@comun/zodComun'


const i18n = RosettasATraductor({
	EmailNecesario: {
		es: 'Email necesario',
		en: 'Email required',
		pt: 'Email necessário'
	},
	EmailInvalido: {
		es: 'Email inválido',
		en: 'Invalid email',
		pt: 'Email inválido'
	},
	PassNecesario: {
		es: 'Contraseña necesaria',
		en: 'Password required',
		pt: 'Senha necessária'
	},
	PassMuyCorto: {
		es: 'Contraseña muy corta',
		en: 'Password too short',
		pt: 'Senha muito curta'
	},
	PassNuevoMuyCorto: {
		es: 'Contraseña nueva muy corta',
		en: 'New password too short',
		pt: 'Nova senha muito curta'
	}
})

export const InputEmailZod = z.preprocess(
	v => typeof v === 'string' ? v : undefined,
	z.string()
		.min(1, i18n('EmailNecesario'))
		.trim()
		.toLowerCase()
		.email({ message: i18n('EmailInvalido') })
)

export const InputPassZod = z.string({ required_error: i18n('PassNecesario') })
	.min(2, i18n('PassMuyCorto'))

export const InputPassNuevoZod = z.string().min(6, i18n('PassNuevoMuyCorto'))

function EmailConCatch(puedeSerEmail: string) {
	try {
		return z.string().email().parse(puedeSerEmail)
	}
	catch (e) {
		return undefined
	}
}

export const EmailOIndefZod = z.preprocess(
	v => (typeof v === 'string' ? EmailConCatch(v.trim()) : undefined),
	z.string().email().optional()
)

export const NotaZod = z.union([
	z.literal(1),
	z.literal(2),
	z.literal(3),
	z.literal(4),
	z.literal(5)
])
export type Nota = z.infer<typeof NotaZod>

