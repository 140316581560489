import { DatosPersistentes as DatosPersistentesMultiplataforma } from 'datos-persistentes';

// * Storage
const nombre = "group.app.boxmagic.grupo"

DatosPersistentesMultiplataforma.configurarAlmacenamiento({
	nombre
})

export type AlmacenamientoEnString = {
	guardar: (clave: string, valor: string) => Promise<void>
	leer: (clave: string) => Promise<string | null>
	borrar: (clave: string) => Promise<void>
	vaciar: () => Promise<void>
}

export const DatosPersistentes: AlmacenamientoEnString = {
	async guardar(clave: string, valor: string) {
		try {
			await DatosPersistentesMultiplataforma.escribir({ clave, valor })
		} catch (e) {
			consolo.info(`No se pudo guardar el contenido en "${ clave }"`, e)
		}
	},

	async leer(clave: string): Promise<string | null> {
		try {
			const { valor } = await DatosPersistentesMultiplataforma.leer({ clave })
			return valor ?? null
		} catch (e) {
			consolo.info(`No se pudo leer el contenido de "${clave}"`, e)
			return null
		}
	},

	async borrar(clave: string) {

		try {
			await DatosPersistentesMultiplataforma.borrar({ clave })
		} catch (e) {
			consolo.info(`No se pudo borrar el contenido de "${clave}"`, e)
		}
	},

	async vaciar() {

		try {
			await DatosPersistentesMultiplataforma.vaciarAlmacenamiento()
		} catch (e) {
			consolo.info('No se pudo vaciar el almacenamiento', e)
		}
	}
}

