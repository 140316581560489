import type { Gimnasio, RegistroDeGimnasios } from "@comun/types"

const ProcesadorDeEventos = function () {

	UsuarioAPI.on('sesionCerrada', async () => {
		const fx = 'UsuarioAPI.on(\'sesionCerrada\')'
		const timerID = await MiniID()
		consolo.time(timerID)
		await Promise.all([
			AppAPI.DesconectarGimnasio(),
			GimnasioAPI.Limpiar(),
			UsuariosAPI.Limpiar(),
			PerfilesEnGimnasioAPI.Limpiar(),
			EspaciosAPI.Limpiar(),
			GimnasiosAPI.Limpiar(),
			NoticiasAPI.Limpiar(),
			InstanciasAPI.Limpiar(),
			RutinasAPI.Limpiar(),
			UsuariosAPI.Limpiar()
		])

		consolo.log(fx)
		consolo.timeEnd(timerID)
	})

	GimnasioAPI.on('cierreSesion', UsuarioAPI.CerrarSesion)

	GimnasiosAPI.on('gimnasiosDisponibles', async (gimsDispo: RegistroDeGimnasios) => {
		if (_.isEmpty(gimsDispo)) {
			consolo.log('No hay gimnasios disponibles')
			return
		}
		if (_.size(gimsDispo) === 1) {
			const gimnasioID = _.first(_.keys(gimsDispo))!
			const gimnasioIDActivo = unref(useGimnasio().gimnasioID)
			if (!gimnasioIDActivo)
				await AppAPI.SeleccionarGimnasio(gimnasioID)
		}
	})

	AppAPI.on('gimnasioID', async () => {
		const fx = 'AppAPI.on(\'gimnasioID\')'
		const timerID = await MiniID()
		consolo.log(fx)
		consolo.time(timerID)

		try {
			GimnasioAPI.Limpiar()
			EspaciosAPI.Limpiar()
			NoticiasAPI.Limpiar()
			InstanciasAPI.Limpiar()
			RutinasAPI.Limpiar()
			UsuariosAPI.Limpiar()

			await new Promise(requestAnimationFrame)

			if (!unref(token)) {
				consolo.log('No hay token, se aborta')
				return
			}

			// const gim = unref(gimnasio)
			const pegs = unref(perfilesEnGimnasios)
			await Promise.all([
				// (!gim || gim.gimnasioID !== gID) ? (async () => {
				// 	consolo.log(fx, 'GimnasioAPI.ObtenerGimnasio()')
				// 	return GimnasioAPI.ObtenerGimnasio()
				// }) : null,

				(!pegs || _.isEmpty(pegs)) ? (async () => {
					consolo.log(fx, 'PerfilEnGimnasioAPI.ObtenerPerfilEnGimnasio()')
					return PerfilEnGimnasioAPI.ObtenerPerfilEnGimnasio()
				}) : null,
			])
		}
		finally {
			consolo.timeEnd(timerID)
		}
	})

	UsuarioAPI.on('usuario', async () => {
		const fx = 'UsuarioAPI.on(\'usuario\''
		consolo.log(fx)
		NotificacionesAPI.suscribir()
		await PrepararSesionEnGimnasio()
	})

	GimnasioAPI.on('gimnasio', async (_gimnasio: Gimnasio) => {
		const fx = 'GimnasioAPI.on(\'gimnasio\')'
		consolo.log(fx)
		await PrepararSesionEnGimnasio()
	})
}



export default defineNuxtPlugin((nuxtApp) => {
	// * Sólo en cliente
	if (!import.meta.client) return

	nuxtApp.hook('app:mounted', () => {
		ProcesadorDeEventos()
		useRegistroInteracciones()

		AppAPI.init()
	})
})
