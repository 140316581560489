const { log, error } = console
function loqsea(..._args: any[]) { }

export const consolo = contextoApp.buildConfig.dev
	? console
	: {
		...console,
		trulog: log,
		info: loqsea,
		log: loqsea,
		time: loqsea,
		timeEnd: loqsea,
		group: loqsea,
		groupEnd: loqsea,
		error
	}
