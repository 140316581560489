import { ZodError, z } from 'zod'
import { consolo } from '../composables/consolo'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const OpcionesZod = z.object({
	error: z.any(),
	datos: z.any().optional(),

	origen: z.string().optional(),
	alertar: z.boolean().optional(),

	respuesta: z.object({
			codigo: z.string(),
			datos: z.any()
		})
		.optional()
})
type Opciones = z.infer<typeof OpcionesZod>

export class ErrorAumentado extends Error {
	version: string
	trazado: string[] = []

	alertar = true

	override cause?: Error
	origen?: string

	datos?: unknown

	respuesta?: {
		codigo: string
		datos?: unknown
	}

	constructor(message: string, opciones?: Opciones) {
		super(message)
		const contexto = unref(contextoApp)
		this.version = contexto ? contexto.version : 'preinit'
		this.name = 'ErrorAumentado'
		this.alertar
			= (!opciones || opciones.alertar === undefined) ? true : opciones.alertar

		if (opciones) {
			if (opciones.origen) {
				this.origen = opciones.origen
				this.trazado.push(opciones.origen)
			}
			this.cause = opciones.error
			if (opciones.error instanceof ZodError) {
				consolo.error('%c\n Error de parseo ', 'background-color: red; font-weight: bold;')
				const issues = opciones.error.issues
				for (const [issueID, issue] of Object.entries(issues)) {
					const {
						path,
						code,
						message,
						expected,
						received
					} = Object.assign({
						expected: '',
						received: ''
					}, issue)
					consolo.error(`Issue ${issueID} @ ${path.join(' . ')}\n`, `expected: ${expected}; received: ${received}\n`, `code: ${code}; message: ${message}`)
				}
			}

			if (opciones.datos)
				this.datos = opciones.datos
			if (this.alertar)
				consolo.error('%c ErrorAumentado: ', message, this.datos, this)
		}
	}

	trazar(fx: string) {
		consolo.log('  Agregado al trazado: ', fx)
		this.trazado.push(fx)
		return this
	}
}
