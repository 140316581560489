import _ from 'lodash'
import { ErrorAumentado } from '@base/lib/error'
import { z } from 'zod'


import { RutinaZod } from '@comun/types'
import type { Rutina } from '@comun/types'
import { ErrorNotificableZod } from '../lib/erroresNotificables'


const consoloRaiz = 'RutinasAPI'
const consoloColor = 'color: PowderBlue'

const rutinasRef = ref<Record<string, Rutina> | null>(null)

export const rutinas = computed(() => {
	const rutinas = unref(rutinasRef)
	if (!rutinas)
		return {}
	return rutinas
})

export const rutinaCategorias = computed(() => {
	const { gimnasio: gimRef } = useGimnasio()
	const gimnasio = unref(gimRef)
	const rutinaCategorias = gimnasio?.rutinaCategorias ?? ({})
	return rutinaCategorias
})

// Obtención
const refrescandoRutinasRef = ref(false)
const refrescandoRutinas = computed(() => unref(refrescandoRutinasRef))

async function ObtenerRutinas(): Promise<boolean> {
	const fx = 'ObtenerRutinas'
	consolo.group(`%c${consoloRaiz} ${fx}`, consoloColor)
	try {
		const headers = HeadersConAuth()
		refrescandoRutinasRef.value = true
		const respuesta = await axiosWorker({
			url: `${contextoApp.buildConfig.apiUrl}/boxmagic/rutinas`,
			method: 'get',
			headers
		})
			

		const parser = z
			.discriminatedUnion('ok', [
				z.object({
					ok: z.literal(true),
					rutinas: z.record(z.string(), RutinaZod)
				}),
				z.object({
					ok: z.literal(false),
					error: ErrorNotificableZod
				})
			])
		const parseo = parser.safeParse(respuesta)

		if (!parseo.success)
			throw new ErrorAumentado('malParseo', { origen: fx, error: parseo.error })

		const resultado = parseo.data
		if (!resultado.ok) {
			consolo.log('error', resultado.error, resultado)
			return false
		}

		const rutinas = resultado.rutinas

		// Reemplazar las rutinas que ya estaban en el registro y agregar las nuevas
		rutinasRef.value = _.merge(rutinasRef.value ?? {}, rutinas)

		return true
	}
	catch (e) {
		if (e instanceof ErrorAumentado)
			throw e.trazar(fx)
		consolo.error(`${fx}: error`, e)
		throw new ErrorAumentado(fx, { error: e })
	}
	finally {
		refrescandoRutinasRef.value = false
		consolo.groupEnd()
	}
}

async function Limpiar() {
	rutinasRef.value = null
}

const enviandoPuntajeRef = ref(false)

async function GuardarPuntaje(datos: {
	rutinaID: string
	categoriaID: string
	puntaje: string | number
}): Promise<boolean> {
	const fx = 'GuardarPuntaje'
	consolo.group(`%c${consoloRaiz} ${fx}`, consoloColor)
	try {
		const { rutinaID, categoriaID, puntaje } = datos
		const headers = HeadersConAuth()
		enviandoPuntajeRef.value = true
		const respuesta = await axiosWorker({
			url: `${contextoApp.buildConfig.apiUrl}/boxmagic/rutinas/desempenio`,
			method: 'post',
			headers,
			data: { rutinaID, categoriaID, puntaje }
		})
			

		const parser = z
			.discriminatedUnion('ok', [
				z.object({
					ok: z.literal(true)
				}),
				z.object({
					ok: z.literal(false),
					error: ErrorNotificableZod
				})
			])
		const parseo = parser.safeParse(respuesta)

		if (!parseo.success)
			throw new ErrorAumentado('malParseo', { origen: fx, error: parseo.error })

		const resultado = parseo.data
		if (!resultado.ok) {
			consolo.log('error', resultado.error, resultado)
			// rutinasRef.value = false
			return false
		}

		// const rutinas = resultado.rutinas
		// rutinasRef.value = rutinas
		ObtenerRutinas()
		return true
	}
	catch (e) {
		if (e instanceof ErrorAumentado)
			throw e.trazar(fx)
		consolo.error(`${fx}: error`, e)
		throw new ErrorAumentado(fx, { error: e })
	}
	finally {
		enviandoPuntajeRef.value = false
		consolo.groupEnd()
	}
}

export function useRutinas() {
	return {
		rutinas,
		refrescandoRutinas,
		rutinaCategorias
	}
}

export const RutinasAPI = {
	ObtenerRutinas,
	GuardarPuntaje,
	Limpiar
}
