import { crearStorage } from './localforage'
import _ from 'lodash'
import { DefinirRosetta, idiomasDisponibles } from '@comun/rosetta'
import type { ColeccionDeTraducciones, IdiomasDisponibles } from '@comun/rosetta'

export type { MicRosetta, ColeccionDeTraducciones, IdiomasDisponibles } from '@comun/rosetta'
export { IdiomasDisponiblesZod } from '@comun/rosetta'

export { idiomasDisponibles } from '@comun/rosetta'

const idiomaStore = crearStorage('idiomaStore')

const idiomaVisibleRef = ref<IdiomasDisponibles>('es')

export const idiomaVisible = computed(() => unref(idiomaVisibleRef))

function identificarLenguajePreferidoPorNavegador(): IdiomasDisponibles | undefined {
	if (typeof navigator === 'undefined')
		return

	const navLangs = _.uniq(_.compact(navigator.languages))
	if (_.isEmpty(navLangs))
		return

	const encontrado = _.find(navLangs, (navlang) => {
		return _.some(idiomasDisponibles, idioma => navlang.startsWith(idioma))
	})
	if (!encontrado)
		return

	const idiomaDisponibleID = _.find(idiomasDisponibles, idiomaID => encontrado.startsWith(idiomaID))

	return idiomaDisponibleID
}

// ==========

let inicializado = false
async function init() {
	if (inicializado)
		return
	inicializado = true
	const recordado = await idiomaStore.getItem<IdiomasDisponibles>('idioma')
	const preferidoPorNavegador = identificarLenguajePreferidoPorNavegador()
	idiomaVisibleRef.value = recordado ?? preferidoPorNavegador ?? 'es'
	console.log('init idiomaActivo', idiomaVisibleRef.value)
}
init()

export function elegirIdiomaVisible(idiomaAVisibilizar: IdiomasDisponibles) {
	console.log('elegirIdiomaVisible', idiomaAVisibilizar)
	idiomaVisibleRef.value = idiomaAVisibilizar
	// Almacenar en localforage
	idiomaStore.setItem('idioma', idiomaAVisibilizar)
}

const RosettasATraductor = DefinirRosetta(idiomasDisponibles)

export function FrontRosetta<
	Traducciones extends ColeccionDeTraducciones,
	TraduccionID extends keyof Traducciones,
>(coleccionDeRosettas: Traducciones) {
	const traductor = RosettasATraductor(coleccionDeRosettas)
	return (traduccionID: TraduccionID) => traductor(traduccionID, unref(idiomaVisible))
}

export default FrontRosetta
