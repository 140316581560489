import _ from 'lodash'
import { z } from 'zod'

export const idiomasDisponibles = ['es', 'en', 'pt'] as const satisfies string[]
export const IdiomasDisponiblesZod = z.enum(idiomasDisponibles)
export type IdiomasDisponibles = typeof idiomasDisponibles[number]

export type MicRosetta = {
	[idiomaID in IdiomasDisponibles]: string
}
export interface ColeccionDeTraducciones {
	[traduccionID: string]: MicRosetta
}

export const ColeccionDeTraduccionesZod = z.record(z.string(), z.record(z.string(), z.string())).refine((coleccion) => {
	const formaZod = z.record(z.string(), z.record(z.string(), z.string()))

	const parseo = formaZod.safeParse(coleccion)

	if (!parseo.success) {
		console.error('Error al parsear ColeccionDeTraducciones', parseo.error)
		return false
	}

	const coleccionParseada = parseo.data

	const idiomasConInfo = _.uniq(_.flatten(_.map(coleccionParseada, traducciones => Object.keys(traducciones))))

	// Revisar que todas las traducciones tengan todos los idiomas
	_.forEach(coleccionParseada, (traducciones, traduccionesID) => {
		_.forEach(idiomasConInfo, (idioma) => {
			if (!traducciones[idioma]) {
				console.error(`Falta traduccion para ${traduccionesID} en ${idioma}`)
				return false
			}
		})
	})
	return true
})

export function DefinirRosetta<IdiomasDisponibles extends string[]>(_idiomas: IdiomasDisponibles) {
	function RosettasATraductor<
		Traducciones extends ColeccionDeTraducciones,
	>(coleccionDeRosettas: Traducciones) {
		// Implementacion de funcion de traduccion

		function traductor<
			TraduccionID extends keyof Traducciones,
			IdiomaDisponibleID extends keyof Traducciones[TraduccionID],
		>(traduccionID: TraduccionID, idiomaID: IdiomaDisponibleID): Traducciones[TraduccionID][IdiomaDisponibleID] | '🪲' {
			try {
				// return rosettaID
				const rosetta = coleccionDeRosettas[traduccionID]
				//    ^?

				const traducido = rosetta?.[idiomaID] ?? '🪲'
				return traducido
			}
			catch (e) {
				console.error('error', e)
				return '🪲'
			}
		}

		return traductor
	}

	RosettasATraductor.elegirIdiomaVisible = (_idioma: IdiomasDisponibles) => {}
	return RosettasATraductor
}
