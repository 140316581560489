declare global {
	interface Window {
		dataLayer?: object[]
	}
}

function push(
	eventName: string,
	variables: Record<string, string | number | null | boolean | undefined>
): void {
	if (!window.dataLayer)
		window.dataLayer = []
	window.dataLayer.push(Object.assign(variables, { event: eventName }))
}

export function useGtmPush() {
	return push
}
