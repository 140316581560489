import { z } from 'zod'
import rosetta from '@base/lib/rosetta'
import { ErrorAumentado } from '@base/lib/error'
import { ErrorNotificableZod, i18nIconos, i18nTapi } from '~/lib/erroresNotificables'

export function HeadersConAuth(tkn?: string): Record<string, string | number> {
	const elToken = tkn ?? unref(token)
	if (!elToken)
		throw new ErrorAumentado('noHayToken', { origen: 'HeadersConAuth' })

	const headersBase = HeadersBase()
	return {
		...headersBase,
		Authorization: `Bearer ${token.value}`
	}
}

export function HeadersBase(): Record<string, string | number> {
	const { gimnasioID } = useGimnasio()
	const headers: {
		[key: string]: string
	} = {
		'Accept': 'application/json',
		'GoTs-App': contextoApp.appConfig.appID,
		'GoTs-Ambiente': contextoApp.buildConfig.modo,
		'GoTs-Dispositivo': contextoApp.buildConfig.dispositivo,
		'GoTs-Version': contextoApp.buildConfig.version
	}
	if (gimnasioID.value)
		headers['GoTs-Gimnasio'] = gimnasioID.value
	return headers
}

const i18nAxios = rosetta({
	errorDeRed: {
		es: 'Error de red',
		en: 'Network error',
		pt: 'Erro de rede'
	},
	sinRespuesta: {
		es: 'Sin respuesta',
		en: 'No response',
		pt: 'Sem resposta'
	},
	malaConfiguracion: {
		es: 'Mala configuración',
		en: 'Bad configuration',
		pt: 'Má configuração'
	},
	noSePudoConectarConAPI: {
		es: 'No se pudo conectar con API',
		en: 'Could not connect to API',
		pt: 'Não foi possível conectar à API'
	},
	noAutorizado: {
		es: 'No autorizado',
		en: 'Unauthorized',
		pt: 'Não autorizado'
	},
	solicitudRechazada: {
		es: 'Solicitud rechazada',
		en: 'Request rejected',
		pt: 'Solicitação rejeitada'
	}
})

const RespuestaConErrorZod = z.object({
	ok: z.literal(false),
	error: z.string()
})

const appNoAutorizadaRef = ref(false)
export const appNoAutorizada = computed(() => appNoAutorizadaRef.value)

const appNoConectadaSQLRef = ref(false)
export const appNoConectadaSQL = computed(() => appNoConectadaSQLRef.value)

const appNoConectadaMongoDBRef = ref(false)
export const appNoConectadaMongoDB = computed(() => appNoConectadaMongoDBRef.value)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function ManejadorErroresAxios(error: { error: string, status?: number, response?: any }) {
	consolo.log('\n\nManejadorErroresAxios error', error);

	// Verificar si es un error de red
	if (error.error.includes('Network Error')) {
		notificadorEnApp.error({
			titulo: i18nAxios('errorDeRed'),
			texto: i18nAxios('noSePudoConectarConAPI'),
			codigo: 'noSePudoConectarConAPI',
			duracionSegundos: 5,
			icono: 'arcticons:trexrunner',
			iconoMedida: '3rem'
		});
		throw new ErrorAumentado('sinConexion');
	}

	// Verificar si hay respuesta disponible
	if (!error.response) {
		throw new ErrorAumentado('sinRespuesta');
	}

	const errorParseo = RespuestaConErrorZod.safeParse(error.response.data);

	if (!errorParseo.success) {
		throw new ErrorAumentado('respuestaConErrorMalParseada', { datos: { responseData: error.response.data } });
	}

	const codigo = errorParseo.data.error;

	if (codigo === 'appNoAutorizada') {
		appNoAutorizadaRef.value = true;
	}

	const errorIDparseo = ErrorNotificableZod.safeParse(codigo)
	const errorID = errorIDparseo.success ? errorIDparseo.data : null
	if (errorID) {
		if (['conexionFallidaSDB', 'conexionRechazadaSDB'].includes(errorID)) {
			appNoConectadaSQLRef.value = true
		}

		if (codigo === 'conexionFallidaDB') {
			appNoConectadaMongoDBRef.value = true
		}

		if (['conexionFallidaSDB', 'conexionRechazadaSDB', 'conexionFallidaDB'].includes(errorID)) {
			notificadorEnApp.error({
				titulo: i18nTapi('appDesactivada'),
				texto: i18nTapi(errorID),
				codigo: errorID,
				icono: i18nIconos[errorID],
			})
			} else {
				notificadorEnApp.error({
					titulo: 'Notificacion temporal para debug',
					texto: i18nTapi(errorID),
					codigo: errorID,
					icono: i18nIconos[errorID]
				})
		}
	}


	if (codigo === 'gimNoAutorizado') {
		AppAPI.DesconectarGimnasio();
	}

	if (codigo === 'cuentaEliminada') {
		UsuarioAPI.CerrarSesion();
	}

	if (codigo === 'usuarioNoRegistrado') {
		const i18n = TrosettaAPI.crear({
			seHaCerradoLaSesion: {
				es: 'Se ha cerrado la sesión',
				en: 'Session has been closed',
				pt: 'A sessão foi encerrada'
			},
			usuarioNoRegistrado: {
				es: 'Usuario no encontrado',
				en: 'User not found',
				pt: 'Usuário não encontrado'
			}
		});
		notificadorEnApp.error({
			titulo: i18n('usuarioNoRegistrado'),
			texto: i18n('seHaCerradoLaSesion'),
			codigo,
			duracionSegundos: 3,
			icono: 'solar:ghost-outline',
			iconoMedida: '3rem'
		});
		UsuarioAPI.CerrarSesion();
		throw new ErrorAumentado('sinConexion');
	}

	return errorParseo.data;
}
