<template lang="pug">
.version.fz07rem.fwm.tar.usn
	.px1ud.aic
		.aic.gap.ffrw(v-if="contextoApp.buildConfig.modo !== 'produccion'") 
			.posf.l0.r0.atencionFondo.h1em.z-1(v-if="staging" style="mask-image: linear-gradient(to left, rgba(0,0,0,.4) 10em, black 20em)")

			//- div {{ hora }} 
			//- div {{ horaConDatePuro }}

			div(v-if="hora === horaConDatePuro") {{ hora }} {{  i18n('zonaHoraria') }} {{ reloj.format('Z') }}
			div(v-else) hora {{ hora }} hcdp {{ horaConDatePuro }} {{ reloj.format('Z') }}

			//- div tz {{ reloj.format('Z') }}

			.fwb(v-if="staging") STAGING&nbsp
			.fwb(v-else) SANDBOX&nbsp
			
			.fwl(v-if="contextoApp.buildConfig.modo !== 'dev'") ({{contextoApp.buildConfig.modo}})&nbsp
		//- .fwb.atencionColor(v-else-if="contextoApp.buildConfig.modo !== 'produccion'") STAGING ({{ contextoApp.buildConfig.modo }})&nbsp
		.op05 v{{ contextoApp.buildConfig.version }} #[span(v-for="rol in susRoles") {{ i18n(rol) }}]
</template>

<script lang="ts" setup>
const staging = contextoApp.buildConfig.adminUrl.startsWith('https://staging')

const hora = computed(() => unref(reloj).format('HH:mm'))
const horaConDatePuro = computed(() => {
	const h = unref(reloj)
	console.log(h.format('HH:mm'))
	const date = new Date()
	const mzp = (`${date.getMinutes()}`).padStart(2, '0')
	return `${date.getHours()}:${mzp}`
})

const i18n = TrosettaAPI.crear({
	staff: {
		es: 'Staff',
		en: 'Staff',
		pt: 'Staff'
	},
	coach: {
		es: 'Coach',
		en: 'Coach',
		pt: 'Coach'
	},
	admin: {
		es: 'Admin',
		en: 'Admin',
		pt: 'Admin'
	},
	recepcion: {
		es: 'Recepción',
		en: 'Reception',
		pt: 'Recepção'
	},
	head_coach: {
		es: 'Head Coach',
		en: 'Head Coach',
		pt: 'Head Coach'
	},
	super_admin: {
		es: 'Super Admin',
		en: 'Super Admin',
		pt: 'Super Admin'
	},
	alumno: {
		es: '',
		en: '',
		pt: ''
	},
	zonaHoraria: {
		es: 'Zona horaria',
		en: 'Time zone',
		pt: 'Fuso horário'
	}
})

const susRoles = computed(() => unref(usuario)?.roles || [])
</script>

<style lang="sass" scoped>
@import '@base/sass/comun'
.version
	position: fixed
	z-index: 100
	top: 0
	right: 0
	line-height: 1
	// border: 2px solid red
	padding-top: const(safe-area-inset-top, .5rem)
	padding-top: env(safe-area-inset-top, .5rem)
	+movil
		padding-top: const(safe-area-inset-top, .1rem)
		padding-top: env(safe-area-inset-top, .1rem)
	+tablet
		padding-top: max(const(safe-area-inset-top, .5rem), .5rem)
		padding-top: max(env(safe-area-inset-top, .5rem), .5rem)
</style>
