import { z } from 'zod'

import {
	NumeroZod,
	StringZod,
	StringOpcionalZod,
	BooleanOpcionalZod,
} from '../../comun/zodComun'
import { TextosDeAppZod } from '../../comun/appsConfigs'

const dispositivos = ['ios', 'web', 'android'] as const

export const DispositivoZod = z.enum(dispositivos)
export type Dispositivo = z.infer<typeof DispositivoZod>

export const ModoZod = z.enum(['dev', 'debug', 'produccion', 'tunel'])

export type Modo = z.infer<typeof ModoZod>

export const AppListadaZod = z.object({
	appID: StringZod,
	nombre: StringZod,
	descripcion: StringZod,

	hosts: z.array(StringZod),
	androidLink: StringOpcionalZod,
	iosLink: StringOpcionalZod,

	deprecar: BooleanOpcionalZod
})

export type AppListada = z.infer<typeof AppListadaZod>

export const ListadoDeAppsZod = z.record(z.string(), AppListadaZod)

export type ListadoDeApps = z.infer<typeof ListadoDeAppsZod>

export const AppConfigFrontZod = z.object({
	appID: StringZod,
	nombre: StringZod,
	descripcion: StringZod,

	hosts: z.array(StringZod),
	androidLink: StringOpcionalZod,
	iosLink: StringOpcionalZod,

	bundleID: StringZod,

	tema: z.enum(['claro', 'oscuro']).optional(),

	splashFondo: z.string(),
	splashFondoOscuro: z.string(),

	permisosDeAnonimo: z.object({
		crearCuenta: BooleanOpcionalZod,
		ingresar: BooleanOpcionalZod,
		cambiarPass: BooleanOpcionalZod
	}).optional(),

	textos: TextosDeAppZod
})

export type AppConfigFront = z.infer<typeof AppConfigFrontZod>

export const BuildConfigFrontZod = z.object({
	dispositivo: DispositivoZod,
	modo: ModoZod,
	version: StringZod,
	versionCode: NumeroZod,
	dev: z.boolean(),
	apiUrl: z.string(),
	adminUrl: z.string(),

	gTagManager: StringOpcionalZod,
	firmaLlavePublica: StringZod,
})

export type BuildConfigFront = z.infer<typeof BuildConfigFrontZod>

export const ContextoAppZod = z.object({
	appConfig: AppConfigFrontZod,
	buildConfig: BuildConfigFrontZod,
})

export type ContextoApp = z.infer<typeof ContextoAppZod>