import _ from 'lodash'
import { DatosPersistentes } from '~/lib/datosPersistentes'
import { NotificacionesAPI } from '@base/composables/notificacionesAPI'

// * Estos se almacenan en el localStorage

export type RecordatorioDeInstancia = {
	instanciaID: string
	usuarioID: string
	claseID: string
	nombre: string
	fechaInicio: Date
}

const MINUTOS_DEFECTO = 15;

const pushRef = ref<boolean>(false)
const pushListaDeEsperaRef = ref<boolean>(false)
const recordatoriosDeSesionesRef = ref<boolean>(false)
const recordatoriosDeSesionesMinutosAntesRef = ref<number>(MINUTOS_DEFECTO)
const recordarVencimientoDePlanRef = ref<boolean>(false)

export const NotificacionesYRecordatorios = {
	push: computed(() => pushRef.value),
	pushListaDeEspera: computed(() => pushListaDeEsperaRef.value),
	recordatoriosDeSesiones: computed(() => recordatoriosDeSesionesRef.value),
	recordatoriosDeSesionesMinutosAntes: computed(() => recordatoriosDeSesionesMinutosAntesRef.value),
	recordarVencimientoDePlan: computed(() => recordarVencimientoDePlanRef.value),

	async alIniciar() {
		consolo.log('alIniciar')
		await this.cargarConfiguracion()
	},

	async cargarConfiguracion() {
		try {
			// * Cargar configuración desde el almacenamiento local
			const push = await DatosPersistentes.leer('push')
			const pushListaDeEspera = await DatosPersistentes.leer('pushListaDeEspera')
			const recordatoriosDeSesiones = await DatosPersistentes.leer('recordatoriosDeSesiones')
			const recordatoriosDeSesionesMinutosAntesLeido = Number(await DatosPersistentes.leer('recordatoriosDeSesionesMinutosAntes')) || MINUTOS_DEFECTO
			const recordarVencimientoDePlanLeido = await DatosPersistentes.leer('recordarVencimientoDePlan')

			consolo.log({
				push,
				pushListaDeEspera,
				recordatoriosDeSesiones,
				recordatoriosDeSesionesMinutosAntesLeido,
				recordarVencimientoDePlanLeido
			})

			pushRef.value = push === 'true'
			pushListaDeEsperaRef.value = pushListaDeEspera === 'true'
			recordatoriosDeSesionesRef.value = recordatoriosDeSesiones === 'true'
			recordatoriosDeSesionesMinutosAntesRef.value = Number.isNaN(recordatoriosDeSesionesMinutosAntesLeido) ? MINUTOS_DEFECTO : recordatoriosDeSesionesMinutosAntesLeido
			recordarVencimientoDePlanRef.value = recordarVencimientoDePlanLeido === 'true'
		} catch (error) {
			console.error('Error cargando la configuración:', error)
		}
	},

	vaciar() {
		if (!unref(NotificacionesAPI.permitidas)) return
		NotificacionesAPI.cancelarTodas()
	},

	async configurar(datos: {
		push?: boolean,
		pushListaDeEspera?: boolean,
		recordatoriosDeSesiones?: boolean,
		recordatoriosDeSesionesMinutosAntes?: number,
		recordarVencimientoDePlan?: boolean,
	}) {
		consolo.log('Recordatorios>configurar', datos)
		try {
			if (typeof datos.push !== 'undefined') {
				DatosPersistentes.guardar('push', datos.push.toString())
				pushRef.value = datos.push
			}
			if (typeof datos.pushListaDeEspera !== 'undefined') {
				DatosPersistentes.guardar('pushListaDeEspera', datos.pushListaDeEspera.toString())
				pushListaDeEsperaRef.value = datos.pushListaDeEspera
			}
			if (typeof datos.recordatoriosDeSesiones !== 'undefined') {
				DatosPersistentes.guardar('recordatoriosDeSesiones', datos.recordatoriosDeSesiones.toString())
				recordatoriosDeSesionesRef.value = datos.recordatoriosDeSesiones
			}
			if (typeof datos.recordatoriosDeSesionesMinutosAntes !== 'undefined') {
				DatosPersistentes.guardar('recordatoriosDeSesionesMinutosAntes', datos.recordatoriosDeSesionesMinutosAntes.toString())
				recordatoriosDeSesionesMinutosAntesRef.value = datos.recordatoriosDeSesionesMinutosAntes
			}
			if (typeof datos.recordarVencimientoDePlan !== 'undefined') {
				DatosPersistentes.guardar('recordarVencimientoDePlan', datos.recordarVencimientoDePlan.toString())
				recordarVencimientoDePlanRef.value = datos.recordarVencimientoDePlan
			}

			NotificacionesAPI.cancelarTodas()
			await this.programarRecordatoriosDeAgenda()
			await this.ProgramarAvisosDePago()
		} catch (error) {
			console.error('Error configurando notificaciones:', error)
		}
	},

	async programarRecordatoriosDeAgenda() {
		const instanciaIDs = _.uniq(_.compact(_.map(unref(reservasFuturas), 'instanciaID')))
		const sesiones = _.filter(unref(instancias), i => instanciaIDs.includes(i.instanciaID))

		if (!unref(NotificacionesAPI.permitidas)) return
		if (!unref(NotificacionesYRecordatorios.recordatoriosDeSesiones)) return

		for (const sesion of sesiones) {
			const clase = (unref(clases) ?? {})[sesion.claseID]
			const uID = unref(usuarioID)
			if (!clase || !uID) continue
			await this.programarRecordatorioDeSesion({
				instanciaID: sesion.instanciaID,
				usuarioID: uID,
				claseID: sesion.claseID,
				nombre: clase.nombre,
				fechaInicio: sesion.fechaInicio
			})
		}
	},

	async programarRecordatorioDeSesion(info: RecordatorioDeInstancia, fechaNotificacion?: Dayjs) {
		consolo.log('programarRecordatorioDeSesion', { info, cuando: fechaNotificacion })
		if (!unref(NotificacionesAPI.permitidas)) {
			console.warn('programarRecordatorioDeSesion CANCELADO, notificaciones no permitidas')
			return
		}
		
		if (!unref(NotificacionesYRecordatorios.recordatoriosDeSesiones)) {
			console.warn('programarRecordatorioDeSesion CANCELADO, recordatorios no habilitados')
			return
		}

		const fechaClase = dayjs(info.fechaInicio)

		if (!fechaNotificacion) fechaNotificacion = fechaClase.subtract(unref(recordatoriosDeSesionesMinutosAntesRef), 'minute')

		consolo.log(`notificador>tipicas>Y la clase será ${fechaNotificacion.to(fechaClase)}`)

		const i18n = TrosettaAPI.crear({
			preparatePara: {
				es: 'Prepárate para',
				en: 'Get ready for',
				pt: 'Prepare-se para'
			},
			haComenzado: {
				es: 'ha comenzado!',
				en: 'has started!',
				pt: 'começou!'
			},
			yaHaComenzado: {
				es: 'Ya ha comenzado!',
				en: 'It has already started!',
				pt: 'Já começou!'
			}
		})
		let titulo = `${i18n('preparatePara')} ${info.nombre}`
		let texto = `${fechaNotificacion.to(fechaClase)} (${fechaClase.format('HH:mm')})`

		if (fechaNotificacion.isAfter(info.fechaInicio)) {
			titulo = `${info.nombre} ${i18n('haComenzado')}`
			texto = `${i18n('yaHaComenzado')} (${fechaClase.format('HH:mm')})`
		}

		const { instanciaID, usuarioID } = info

		await NotificacionesAPI.programar({
			id: `instancia-${instanciaID}`,
			usuarioID,

			titulo,
			texto,

			fechaEnQueMostrar: fechaNotificacion.toDate(),
			urlQueAbrir: '/app/horarios',

			data: {
				instanciaID,
				fechaInicio: info.fechaInicio,
				url: '/app/perfil?instanciaID=' + instanciaID
			}
		})
	},

	async ProgramarAvisosDePago(cuando?: Dayjs) {
		const ahora = dayjs()
		const usuarioID = unref(usuario)?.usuarioID
		if (!usuarioID) return
		if (!unref(NotificacionesAPI.permitidas)) return
		if (!unref(NotificacionesYRecordatorios.recordarVencimientoDePlan)) return

		const i18n = TrosettaAPI.crear({
			tuPlanExpira: {
				es: 'Tu plan expira',
				en: 'Your plan expires',
				pt: 'Seu plano expira'
			},
			tuPlanExpiraEn: {
				es: 'Tu plan expira en',
				en: 'Your plan expires in',
				pt: 'Seu plano expira em'
			},
			tuPlanHaExpirado: {
				es: 'Tu plan ha expirado',
				en: 'Your plan has expired',
				pt: 'Seu plano expirou'
			},
			evitaContratiemposManteniendoTuPlanVigente: {
				es: 'Evita contratiempos manteniendo tu plan vigente',
				en: 'Avoid setbacks by keeping your plan up to date',
				pt: 'Evite contratempos mantendo seu plano atualizado'
			},
			recuerdaExtenderVigencia: {
				es: 'Recuerda extender su vigencia para evitar contratiempos',
				en: 'Remember to extend its validity to avoid setbacks',
				pt: 'Lembre-se de estender sua validade para evitar contratempos'
			}
		})
		dayjs.locale(unref(TrosettaAPI.idiomaVisible))
		const vigentes = unref(membresiasVigentes)
		if (!_.isEmpty(vigentes)) {
			_.forEach(vigentes, membresia => {
				const finVigencia = dayjs(membresia.finVigencia)

				const unos5DiasAntes = finVigencia.subtract(5, 'days').hour(12).minute(0)
				const unDiaAntes = finVigencia.subtract(1, 'days').hour(12).minute(0)
				const mismoDia = finVigencia.hour(12).minute(0)

				if (cuando && cuando.isBefore(dayjs())) cuando = dayjs().add(2, 'seconds')

				const momentos = cuando ? { cuando } : { unos5DiasAntes, unDiaAntes, mismoDia }

				for (const [tiempoID, fechaEnQueMostrar] of Object.entries(momentos)) {
					if (typeof fechaEnQueMostrar === 'undefined') continue
					
					if (fechaEnQueMostrar.isBefore(ahora, 'minute'))
						continue

					NotificacionesAPI.programar({
						id: `recordatorio&planID=${membresia.planID}&tiempoID=${tiempoID}`,
						usuarioID,

						titulo: `${i18n('tuPlanExpira')} ${finVigencia.from(ahora)}`,
						texto: i18n('recuerdaExtenderVigencia'),

						fechaEnQueMostrar: fechaEnQueMostrar.toDate(),
						urlQueAbrir: '/app/tienda?planID=' + membresia.planID,

						data: {
							planID: membresia.planID,
							url: '/app/tienda?planID=' + membresia.planID
						}
					})
				}
			})
		}
	},

	notificacionDeActivacion() {
		const u = unref(usuario)
		if (!u) return

		const usuarioID = u.usuarioID
		if (!unref(NotificacionesAPI.permitidas)) return

		const i18n = TrosettaAPI.crear({
			siActivadasTitulo: {
				pt: 'Notificações ativadas!',
				en: 'Notifications enabled!',
				es: 'Notificaciones activadas!'
			},
			siActivadasDescripcion: {
				es: 'Ya no perderás tus próximas sesiones, recibirás una notificación como esta antes de cada una',
				en: 'You will no longer miss your next sessions, you will receive a notification like this before each one',
				pt: 'Você não perderá mais suas próximas sessões, receberá uma notificação como esta antes de cada uma'
			}
		})
		const titulo = i18n('siActivadasTitulo')
		const texto = i18n('siActivadasDescripcion')

		NotificacionesAPI.programar({
			id: `prueba-${new Date().getTime()}`,
			usuarioID,

			titulo,
			texto,

			fechaEnQueMostrar: dayjs().add(1, 'second').toDate(),
			data: {
				tipo: 'prueba',
				numero: 100,
				arreglo: [2, 'a']
			},

			urlQueAbrir: '/app/perfil',
		})
	},
}
