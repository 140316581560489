import _ from 'lodash'
import type { ProgramaConRelacionados, RegistroDeProgramasConRelacionados } from '@comun/types'
import { gimnasio } from './gimnasio'

export const programas = computed(() => {
	if (!gimnasio.value)
		return {}
	const gim = gimnasio.value
	const programas = gim.programas

	const programasConRelacionados: RegistroDeProgramasConRelacionados = {}
	_.forEach(Object.keys(programas), (programaID) => {
		const programa = programas[programaID]
		const clases = _.pickBy(
			gim.clases,
			clase => !_.isEmpty(_.intersection(clase.programasIDs, [programaID]))
		)

		const planes = _.pickBy(
			gim.planes,
			plan => !_.isEmpty(_.intersection(plan.programasIDs, [programaID]))
		)

		const programaConRelacionados: ProgramaConRelacionados = {
			...programa,
			clases,
			planes
		}
		programasConRelacionados[programaID] = programaConRelacionados
	})

	return programasConRelacionados
})

export function useProgramas() {
	return {
		programas
	}
}
