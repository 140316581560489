import _ from 'lodash'
import { z } from 'zod'
import type { UnwrapRef } from 'vue'

import { Capacitor } from '@capacitor/core'
import { getActiveHead } from 'unhead'
import { StatusBar, Style } from '@capacitor/status-bar'
import { Apariencia } from 'capacitor-apariencia'

import { ErrorAumentado } from '@base/lib/error'
import { crearStorage } from '@base/lib/localforage'

const consoleRaiz = 'Plugins TemaOscuro'

const temaStore = crearStorage('temaStore')

const EstadoZod = z.literal('claro').or(z.literal('oscuro').or(z.literal(null)))
type Estado = z.infer<typeof EstadoZod>

export type ConfiguracionTema = 'claro' | 'oscuro' | null

const OSTemaDarks = ref<boolean>(false)
const predefinido = contextoApp.appConfig.tema
const temaPagina = ref<ConfiguracionTema>(null)
const preferido = ref<ConfiguracionTema>(null)
const bloqueado = ref<boolean>(!!predefinido)

const oscuroActivo = computed((): boolean => {
	const utemaPagina = unref(temaPagina)
	const upreferido = unref(preferido)
	const uOSTemaDarks = unref(OSTemaDarks)
	// const uBloqueado = unref(bloqueado)

	const activo = (() => {
		if (utemaPagina)
			return utemaPagina === 'oscuro'
		if (predefinido)
			return predefinido === 'oscuro'
		if (upreferido)
			return upreferido === 'oscuro'
		return uOSTemaDarks
	})()
	// console.log({ utemaPagina, predefinido, uBloqueado, upreferido, uOSTemaDarks, activo })
	return activo
})

let statusBarLight = contextoApp.appConfig.splashFondo;
let statusBarDark = contextoApp.appConfig.splashFondoOscuro;
let navBarLight = contextoApp.appConfig.splashFondo;
let navBarDark = contextoApp.appConfig.splashFondoOscuro;

function encontrarColor(cssvar: string): string {
	const color = window.getComputedStyle(document.body).getPropertyValue(cssvar).trim()
	// console.log('encontrarColor', { cssvar, color })

	if (color === '#000')
		return '#000000'
	if (color === 'black')
		return '#000000'
	if (color === '#fff')
		return '#FFFFFF'
	if (color === 'white')
		return '#FFFFFF'
	return color
}

async function refrescarColores() {
	try {
		statusBarLight = typeof window === 'undefined' ? contextoApp.appConfig.splashFondo : encontrarColor('--bodyFondo-light')
		statusBarDark = typeof window === 'undefined' ? contextoApp.appConfig.splashFondoOscuro : encontrarColor('--bodyFondo-dark')

		navBarLight = statusBarLight
		navBarDark = statusBarDark

		// Si alguno tiene un length menor a 7, el color no es válido
		if (_.some([navBarLight, navBarDark, statusBarLight, statusBarDark], color => !color || color.length < 7))
			throw new ErrorAumentado('refrescarColores, colores no válidos', { datos: { navBarLight, navBarDark, statusBarLight, statusBarDark } })

		// console.log('colores refrescados', { navBarLight, navBarDark, statusBarLight, statusBarDark })
	} catch (e) {
		console.error(e)
	}
}

async function configurarHead() {
	const fx = 'configurarHead'
	console.log(`${consoleRaiz} ${fx}`)
	try {
		const colorDeTema = computed(() => unref(oscuroActivo) ? navBarDark : navBarLight)
		// const clase = computed(() => unref(oscuroActivo) ? 'oscuro' : 'claro')
		const head = {
			meta: [
				{
					hid: 'theme-color',
					name: 'theme-color',
					content: colorDeTema
				},
				{
					hid: 'msapplication-TileColor"',
					name: 'msapplication-TileColor"',
					content: colorDeTema
				}
			],
			htmlAttrs: {
				class: {
					oscuro: () => unref(oscuroActivo),
					claro: () => !unref(oscuroActivo)
				}
			}
			// bodyAttrs: {
			// 	'class': clase,
			// }
		}

		const headActivo = getActiveHead()
		if (headActivo)
			headActivo.push(head)
	}
	catch (e) { console.error(e) }
}

async function configurarStatusBarYNavigationBar() {
	try {
		const oscuroActivoAhora = unref(oscuroActivo)
		const colorFondo = oscuroActivoAhora ? statusBarDark : statusBarLight
		// console.log('configurarStatusBarYNavigationBar', 'oscuroActivoAhora', oscuroActivoAhora, 'colorFondo', colorFondo )

		// if (contextoApp.buildConfig.dispositivo !== 'android') {
		// 	console.log('No es android, se aborta.')
		// 	return
		// }

		if (Capacitor.isPluginAvailable('StatusBar')) {
			const style = oscuroActivoAhora ? Style.Dark : Style.Light
			await Promise.all([
				await StatusBar.setStyle({ style }),
				await StatusBar.setBackgroundColor({ color: colorFondo }),
			])
		}

		await Apariencia.setStatusBarColor({ color: colorFondo })
		await Apariencia.setNavigationBarColor({ color: colorFondo })

		// if (Capacitor.isPluginAvailable('NavigationBar')) {
		// 	// await NavigationBar.setTransparency({ isTransparent: true })
		// 	await NavigationBar.setColor({
		// 		color: colorFondo,
		// 		darkButtons: oscuroActivoAhora
		// 	})
		// }
	}
	catch (e) { console.error(e) }
}

async function propagarTema() {
	if (typeof window === 'undefined') return
	const fx = 'propagarTema'
	console.log(consoleRaiz, fx)
	try {
		await refrescarColores()
		await configurarStatusBarYNavigationBar()
		configurarHead()
	}
	catch (e) { console.error(e) }
}


function configurarPreferido(elegido: Estado) {
	console.log('configurarPreferido', elegido)
	try {
		preferido.value = elegido
		if (elegido)
			temaStore.setItem('preferido', elegido)
		else temaStore.removeItem('preferido')
	}
	catch (e) { console.error(e) }
}

function identificarTemaOS(): boolean {
	const fx = 'identificarTemaOS'
	console.log(`${consoleRaiz} ${fx}`)
	try {
		if (typeof window === 'undefined') {
			throw new ErrorAumentado(
				'No se puede identificar el tema por defecto en el servidor'
			)
		}
		const soportado
			= window.matchMedia
			&& typeof window.matchMedia === 'function'
		if (!soportado) {
			throw new ErrorAumentado(
				'No se puede identificar el tema por defecto en este navegador'
			)
		}

		OSTemaDarks.value = window.matchMedia('(prefers-color-scheme: dark)').matches
		window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (event) => {
			OSTemaDarks.value = event.matches
		})
		return OSTemaDarks.value
	}
	catch (e) {
		console.error(e)
		throw e
	}
}

function teclasTemaOscuro(event: KeyboardEvent) {
	if (event.code === 'KeyD' && event.altKey) {
		const estadoNuevo = !oscuroActivo.value ? 'oscuro' : 'claro'
		console.log('tema preferido cambiado a ', estadoNuevo)
		configurarPreferido(estadoNuevo)
	}
}

let recordado = false

async function recordarPreferido() {
	if (recordado)
		return
	const modoPreferidoRescatado = temaStore ? await temaStore.getItem<Estado>('preferido') : null
	const modoPreferidoRescatadoParseado = parsear(modoPreferidoRescatado)
	if (modoPreferidoRescatadoParseado)
		configurarPreferido(modoPreferidoRescatadoParseado)
	recordado = true
}

const init = async () => {
	// * Solo en cliente
	if (import.meta.env.SSR)
		return

	console.log('\n\n\ntemaOscuro init')

	identificarTemaOS()
	recordarPreferido()
	temaPagina.value = parsearTemaDeRuta(useRoute())
	document.addEventListener('keydown', teclasTemaOscuro)

	watch(oscuroActivo, () => {
		propagarTema()
	}, { immediate: true })
}

export const TemaOscuroAPI: {
	init: () => void
	configurarPreferido: (elegido: Estado) => void
	propagarTema: () => void
	refrescarTemaDeRuta: () => void

	activo: ComputedRef<boolean>
	bloqueado: ComputedRef<boolean>
	temaOS: ComputedRef<boolean>
	preferido: ComputedRef<ConfiguracionTema>
} = {
	init,
	configurarPreferido,
	propagarTema,
	refrescarTemaDeRuta: () => {
		console.log('\ntemaOscuro refrescarTemaDeRuta')
		temaPagina.value = parsearTemaDeRuta(unref(useRouter().currentRoute))
	},

	activo: computed(() => oscuroActivo.value),
	bloqueado: computed(() => bloqueado.value),
	temaOS: computed(() => OSTemaDarks.value),
	preferido: computed(() => preferido.value)
}

function parsear(supuesto: unknown): Estado {
	const parseo = EstadoZod.safeParse(supuesto)
	if (!parseo.success)
		return null
	return parseo.data
}

type Ruta = UnwrapRef<ReturnType<typeof useRouter>['currentRoute']>

function parsearTemaDeRuta(ruta: Ruta): Estado {
	return parsear(ruta.meta.tema)
}

export const refrescarTemaDeRuta = () => {
	console.log('\ntemaOscuro refrescarTemaDeRuta')
	temaPagina.value = parsearTemaDeRuta(unref(useRouter().currentRoute))
}