import _ from 'lodash'
import { ErrorAumentado } from '@base/lib/error'
import dayjs, { Dayjs } from 'dayjs'
import type {
	ExtractoGimnasio,
	MembresiaConRelacionados,
	PagoMembresia,
	PerfilEnGimnasio,
	RegistroDeMembresias,
	Reserva
} from '@comun/types'
import { ConformarIntanciaID } from '@comun/instancias'

export function filtrarPagosConVigenciaRestante(
	pagos: Record<string, PagoMembresia>,
	fechaDeComparacion?: Dayjs,
): Record<string, PagoMembresia> {
	const lafecha = fechaDeComparacion || unref(hoy)
	const pagosConVigenciaRestante = _.pickBy(pagos, (pago) => {
		return lafecha.isBefore(pago.finVigencia)
	})
	return pagosConVigenciaRestante
}

export function filtrarMembresiasVigentes<
	Registro extends RegistroDeMembresias
>(
	membresias: Registro,
	fechaDeComparacion?: Dayjs
): Registro {
	const lafecha = fechaDeComparacion || unref(hoy)
	if (!membresias)
		return {} as Registro
	return _.pickBy(membresias, (membresia) => {
		if (!membresia.activa)
			return false
		const pagos = membresia.pagos
		if (!pagos)
			return false
		const pagosVigentes = filtrarPagosConVigenciaRestante(pagos, lafecha)
		return !_.isEmpty(pagosVigentes)
	}) as Registro
}

export function RelacionarMembresiasYReservas<
	SuGimnasio extends ExtractoGimnasio,
>(datos: {
	gimnasio: SuGimnasio
	perfilEnGimnasio: PerfilEnGimnasio
	origen: string
}): {
	membresias: Record<string, MembresiaConRelacionados>
	reservas: Record<string, Reserva>
} {
	const fx = 'RelacionarMembresiasYReservas'
	const { gimnasio, perfilEnGimnasio: perfilEnGim, origen } = datos
	const label = `${fx} ${perfilEnGim.gimnasioID} > perfilID ${perfilEnGim.perfilEnGimnasioID} ${origen} ${dayjs().format()}`
	consolo.time(label)
	try {
		const { membresias, reservas, reservasNoAsignadas } = perfilEnGim

		const planes = GenerarPlanesConRelacionados(gimnasio)
		const programas = gimnasio.programas
		const clases = gimnasio.clases

		const membresiasConRelacionados: Record<string, MembresiaConRelacionados> = {}

		_.forEach(membresias, (membresia, membresiaID) => {
			const suPlan = planes[membresia.planID]
			if (!suPlan) {
				consolo.error('No se encontró el plan de la membresía', {
					membresiaID,
					membresia,
					planes
				})
				return
			}

			const susProgramas = _.pick(programas, suPlan.programasIDs)
			const susClases = _.pickBy(clases, (clase) => {
				return (
					_.intersection(clase.programasIDs, Object.keys(susProgramas)).length
					> 0
				)
			})

			const membresiaConRelacionados: MembresiaConRelacionados = {
				...membresia,
				plan: suPlan,
				programas: susProgramas,
				clases: susClases
			}
			membresiasConRelacionados[membresiaID] = membresiaConRelacionados
		})

		const reservasConjuntas = Object.assign({}, reservas, reservasNoAsignadas)

		const RegistroDeReservasConInstanciaID: Record<string, Reserva> = {}
		_.forEach(reservasConjuntas, (reserva, reservaID) => {
			const { fechaYMD, claseID, horarioID } = reserva
			const instanciaID = ConformarIntanciaID({ fechaYMD, claseID, horarioID })

			RegistroDeReservasConInstanciaID[reservaID] = {
				...reserva,
				instanciaID
			}
		})

		return {
			membresias: membresiasConRelacionados,
			reservas: RegistroDeReservasConInstanciaID
		}
	}
	catch (e) {
		if (e instanceof ErrorAumentado)
			throw e.trazar(fx)
		consolo.error('error', e)
		throw new ErrorAumentado(fx, { error: e })
	}
	finally {
		consolo.timeEnd(label)
	}
}
