import type {
	Gimnasio,
	Participante
} from '@comun/types'

const participantesRef = ref<Record<string, Participante>>({})

export const participantes = computed(() => unref(participantesRef))
const ultimoUpdateParticipantesRef = ref<Dayjs | null>(null)

function IntegrarParticipantes(datos: {
	gimnasio: Gimnasio
	participantes: Record<string, Participante>
}) {
	const lista: Record<string, Participante> = participantesRef.value
	

	for (const [participanteID, participante] of Object.entries(datos.participantes)) {
		if (lista[participanteID]) {
			if (!participante.perfilEnGimnasio) {
				lista[participanteID].miniusuario = participante.miniusuario
				continue
			}
		}
		lista[participanteID] = participante
	}

	participantesRef.value = lista
}

function miniusuarioPorID(usuarioID: string) {
	const participantes = unref(participantesRef)
	const participante = participantes[usuarioID]
	return participante?.miniusuario || null
}

// =====

async function Limpiar() {
	participantesRef.value = {}
	ultimoUpdateParticipantesRef.value = null
}

export const UsuariosAPI = {
	IntegrarParticipantes,
	Limpiar,

	miniusuarioPorID
}
