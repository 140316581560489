import revive_payload_client_Hjmf4WeR2N from "/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_ySWanUoDla from "/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Or2oaplyd8 from "/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import navigation_repaint_client_iIdmaAQMx1 from "/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_I8AhPB5cLY from "/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_Wpj0cDp0U1 from "/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/.nuxt/components.plugin.mjs";
import prefetch_client_GGXNTOA1qA from "/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import _01_inicio_3tcqQ2W1rX from "/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/layers/base/plugins/01.inicio.ts";
import baseapp_9UfP76L5Md from "/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/layers/base/plugins/baseapp.ts";
import reportesDeError_jPg0PXd07h from "/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/layers/base/plugins/reportesDeError.ts";
import _001_nativapp_client_LEuMR6ddCh from "/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/plugins/001.nativapp.client.ts";
import _01_tapi_bDUw3MfTUD from "/Users/camo/Documents/Proyectos/Boxmagic/go-monorepo/front/clientes/usoDiario/plugins/01.tapi.ts";
export default [
  revive_payload_client_Hjmf4WeR2N,
  unhead_ySWanUoDla,
  router_Or2oaplyd8,
  navigation_repaint_client_iIdmaAQMx1,
  check_outdated_build_client_I8AhPB5cLY,
  chunk_reload_client_Wpj0cDp0U1,
  components_plugin_KR1HBZs4kY,
  prefetch_client_GGXNTOA1qA,
  _01_inicio_3tcqQ2W1rX,
  baseapp_9UfP76L5Md,
  reportesDeError_jPg0PXd07h,
  _001_nativapp_client_LEuMR6ddCh,
  _01_tapi_bDUw3MfTUD
]