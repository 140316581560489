import { ErrorAumentado } from './error'
import dayjs from './fechas'
import type { Gimnasio, InstanciaCalculada } from './types'

export function CalcularInstanciaPorSuID<Gim extends Gimnasio>(datos: {
	gimnasio: Gim
	instanciaID: string
}): InstanciaCalculada {
	const { gimnasio, instanciaID } = datos
	const [fechaYMD, claseID, horarioID] = instanciaID.slice(1).split('>')
	return CalcularInstanciaDesdeIDs({ gimnasio, claseID, horarioID, fechaYMD })
}
export function ConformarIntanciaID(datos: {
fechaYMD: string
claseID: string
horarioID: string
}) {
	const { fechaYMD, claseID, horarioID } = datos
	return `i${fechaYMD}>${claseID}>${horarioID}`
}

export function CalcularInstanciaDesdeIDs<Gim extends Gimnasio>(datos: {
	gimnasio: Gim
	claseID: string
	horarioID: string
	fechaYMD: string
}): InstanciaCalculada {
	// const fx = 'CalcularInstanciaDesdeID'

	const { gimnasio, claseID, horarioID, fechaYMD } = datos
	const timezone = gimnasio.perfilDelCentro.timezone
	if (!timezone)
		throw new ErrorAumentado('gimnasioSinTimezone', { error: 'El gimnasio no tiene timezone' })

	if (!gimnasio.clases || !Object.values(gimnasio.clases).length)
		throw new ErrorAumentado('gimnasioSinClases', { error: 'El gimnasio no tiene clases' })

	const feriados = gimnasio.feriados || {}
	const feriadosAno = feriados[fechaYMD.slice(0, 4)] || []
	const esFeriado = feriadosAno.includes(fechaYMD)

	const claseIDs = Object.keys(gimnasio.clases)
	// console.log('claseIDs', Object.keys(gimnasio.clases))
	// console.log('claseID', claseID)
	// console.log('claseIDs.includes(claseID)', claseIDs.includes(claseID))
	const clase = gimnasio.clases[claseID]
	if (!clase) {
		console.error('No existe clase con id', claseID, 'claseIDs', claseIDs)
		throw new ErrorAumentado('noExisteClase', { error: `No existe clase con id ${claseID}`, datos: { claseID } })
	}
	const horario = clase.horarios[horarioID]
	// if (!horario) throw new ErrorAumentado(`No existe horario con id ${horarioID}`)
	if (!horario)
		throw new ErrorAumentado('noExisteHorario', { error: `No existe horario con id ${horarioID}` })

	const salaID = horario.salaID
	if (salaID) {
		const salaSinReservas = gimnasio.salas[salaID]
		// if (!salaSinReservas) throw new ErrorAumentado(`No existe sala con id ${horario.salaID}`)
		if (!salaSinReservas)
			throw new ErrorAumentado('noExisteSala', { error: `No existe sala con id ${salaID}` })
	}

	const fechaInicio = dayjs.tz(`${fechaYMD}T${horario.horaInicio}`, timezone)
	let fechaFin = dayjs.tz(`${fechaYMD}T${horario.horaFin}`, timezone)
	if (fechaFin.isBefore(fechaInicio))
		fechaFin = fechaFin.add(1, 'day')

	const instanciaCalculada: InstanciaCalculada = {
		instanciaID: `i${fechaYMD}>${claseID}>${horarioID}`,

		claseID,
		horarioID,
		fechaYMD,

		fechaInicio: fechaInicio.toDate(),
		fechaFin: fechaFin.toDate(),
		esFeriado
	}
	return instanciaCalculada
}

export function CalcularInstanciaDesdeID(datos: {
	gimnasio: Gimnasio
	instanciaID: string
}): InstanciaCalculada {
	// const fx = 'CalcularInstanciaDesdeID'
	// consolo.group(`%c${consoloRaiz} ${fx}`, consoloColor)

	try {
		const { gimnasio, instanciaID } = datos
		const idsParseables
			= instanciaID.charAt(0) === 'i' ? instanciaID.slice(1) : instanciaID
		const [fechaYMD, claseID, horarioID] = idsParseables.split('>')

		const instanciaCalculada = CalcularInstanciaDesdeIDs({
			gimnasio,
			fechaYMD,
			claseID,
			horarioID
		})

		return instanciaCalculada
	}
	catch (e) {
		console.error('error', e)
		throw e
	}
	// finally {
	// 	consolo.groupEnd()
	// }
}
