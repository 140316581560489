import _ from 'lodash'

import type { NotificacionAumentada } from '@comun/notificaciones.types'
import { viaNotiWorker } from '../lib/notificaciones/web'
import { ConectorNativo } from '../lib/notificaciones/nativas'

import { consolo } from './consolo'
import { contextoApp } from './contextoApp'
import { notificadorEnApp } from './notificadorEnApp'

const webPushPosible = typeof window !== 'undefined' && [
	'Notification' in window,
	'serviceWorker' in navigator,
	'PushManager' in window
].every(Boolean)

const interfazLocal = contextoApp.buildConfig.dispositivo === 'web' ? viaNotiWorker : 
	ConectorNativo

const incapaz = contextoApp.buildConfig.dispositivo === 'web' && !webPushPosible
const notificacionesPermitidasRef = ref<boolean>(false)
const notificacionesActivasRef = ref<boolean>(false)

// * Estos se almacenan en el localStorage

export const NotificacionesAPI = {
	async init() {
		try {
			interfazLocal.on('cambioPermiso', (notificacionesPermitidas: boolean) => {
				consolo.log('Notificaciones>cambioPermiso', 'notificacionesPermitidas:', notificacionesPermitidas)
				notificacionesPermitidasRef.value = notificacionesPermitidas
			})

			interfazLocal.on('notificacionRecibida', (notificacion: NotificacionAumentada) => {
				consolo.log('notificacionRecibida', notificacion)
				notificadorEnApp.info({
					titulo: notificacion.titulo,
					texto: notificacion.texto,
					icono: notificacion.icono || undefined
				})
			})

			interfazLocal.on('notificationClick', (notificacion: NotificacionAumentada) => {
				consolo.log('notificacionClick', notificacion)
				if (!_.isObject(notificacion))
					return
				const { id, fecha, tipo } = notificacion.data
				if (tipo === 'clase') {
					navigateTo(`/app/eventos/sesiones/reservas?id=${id}&fecha=${fecha}`)
				}
			})

			interfazLocal.on('notificationClose', (notificacion: NotificacionAumentada) => {
				consolo.log('notificacionClose', notificacion)
			})

			await interfazLocal.init()

			await NotificacionesYRecordatorios.alIniciar()
		} catch (error) {
			console.error('Error en NotificacionesAPI init', error)
		}
	},

	async suscribir() {
		if (!unref(NotificacionesAPI.activas)) return
		if (!unref(NotificacionesYRecordatorios.push)) return
		if ('suscribir' in interfazLocal)
			return await interfazLocal.suscribir()
	},

	async pedirPermiso() {
		const resultado = await interfazLocal.pedirPermiso()
		const permitido = resultado === 'granted'
		notificacionesPermitidasRef.value = permitido
		return permitido
	},

	cancelarTodas() {
		return interfazLocal.cancelarTodas()
	},

	async programar(
		notificacion: NotificacionAumentada
	) {
		console.log('notificador>programar', { notificacion })

		const ahora = dayjs()
		if (dayjs(notificacion.fechaEnQueMostrar).isBefore(ahora))
			notificacion.fechaEnQueMostrar = ahora.add(2, 's').toDate()

		await interfazLocal.programar(notificacion)
		consolo.log(`Notificacion programada para ${dayjs(notificacion.fechaEnQueMostrar).format('h:mma D MMM')}`, notificacion)
	},

	incapaz,
	permitidas: computed(() => unref(notificacionesPermitidasRef)),
	activas: computed(() => unref(notificacionesActivasRef))
}


