import _ from 'lodash'
import type {
	Gimnasio,
	Instancia,
	InstanciaCalculada,
	Membresia,
	PagoMembresia,
	PeriodoDeVigenciaDeCupos,
	Plan,
	PosibilidadDeReserva,
	ReservaEnPago,
	Reservabilidad
} from './types'

import type { Dayjs } from 'dayjs'

import dayjs from './fechas'

export function calcularCuposDisponiblesPago(datos: {
	pago: PagoMembresia
	plan: Plan
	fecha: Dayjs
}): number {
	const { pago, plan, fecha } = datos
	if (plan.cuposIlimitados)
		return 1

	const {
		periodosPasados,
		periodosDisponibles
	} = _.groupBy(pago.periodosDeCupos, p => dayjs(p.fechaFin).isAfter(fecha) ? 'periodosDisponibles' : 'periodosPasados')

	const cuposUsadosEnPeriodosPasados = _.sum((periodosPasados || []).map(p => p.stats.cuposUsados))
	const cuposUsadosEnPeriodosDisponibles = _.sum((periodosDisponibles || []).map(p => p.stats.cuposUsados))

	const cuposDePeriodosPasados = (plan.cupos * _.size((periodosPasados || [])))
	const balancePasado = cuposDePeriodosPasados - cuposUsadosEnPeriodosPasados - pago.cuposDescontados

	const cuposDePeriodosDisponibles = (plan.cupos * _.size((periodosDisponibles || [])))

	const cuposDisponibles = cuposDePeriodosDisponibles - cuposUsadosEnPeriodosDisponibles + (balancePasado < 0 ? balancePasado : 0) + pago.cuposAgregados

	return cuposDisponibles
}

export function calcularCuposDisponiblesPeriodoFecha(datos: {
	pago: PagoMembresia
	plan: Plan
	periodo: PeriodoDeVigenciaDeCupos
	fecha: Dayjs
}): number {
	const { pago, plan, periodo, fecha } = datos
	const cuposDisponiblesEnPeriodo = (plan.cupos ?? 0) - periodo.stats.cuposUsados
	const cuposDisponiblesEnPago = calcularCuposDisponiblesPago({ pago, plan, fecha })
	const cuposDisponibles = Math.min(cuposDisponiblesEnPeriodo, cuposDisponiblesEnPago)
	return cuposDisponibles
}

export function CalcularPosibilidadesDeReservaConMembresia(datos: {
	gimnasio: Gimnasio
	instancia: InstanciaCalculada
	membresia: Membresia
}): PosibilidadDeReserva[] {
	// const fx = 'CalcularPosibilidadesDeReservaConMembresia'
	const { gimnasio, instancia, membresia } = datos

	try {
		const limitarReservasDiariasPorMembresia = gimnasio.restricciones.limitarReservasDiariasPorMembresia

		// * Programas
		const plan = gimnasio.planes[membresia.planID]
		const membresiaProgramaIDs = plan.programasIDs
		const clase = gimnasio.clases[instancia.claseID]
		const instanciaProgramaIDs = clase.programasIDs
		const programasCompatibles = _.intersection(
			membresiaProgramaIDs,
			instanciaProgramaIDs
		)
		if (_.isEmpty(programasCompatibles))
			return []
		if (!membresia.activa)
			return []

		// * Pagos vigentes
		const pagosVigentesParaInstancia = _.pickBy(membresia.pagos, (pago) => {
			return dayjs(instancia.fechaInicio).isBetween(pago.inicioVigencia, pago.finVigencia, 'day', '[]')
		})
		if (_.isEmpty(pagosVigentesParaInstancia))
			return []

		const posibilidades: PosibilidadDeReserva[] = []

		for (const pago of _.values(pagosVigentesParaInstancia)) {
			// * Cupos en plazo de cupos vigente
			const periodoDeCupos = _.find(pago.periodosDeCupos, periodo => dayjs(instancia.fechaInicio).isBetween(periodo.fechaInicio, periodo.fechaFin, 'day', '[]'))

			if (!periodoDeCupos) {
				console.warn('No se encontró periodo de cupos para la instancia', { membresiaID: membresia.membresiaID, pagoID: pago.pagoID, instanciaID: instancia.instanciaID })
				continue
			}

			// * Si ya tiene una reserva, y el plan no permite multiples reservas por sesion, no puede reservar
			const reservasYaExistentes: ReservaEnPago[] = []

			for (const reserva of _.values(periodoDeCupos.reservas)) {
				if (reserva.claseID === instancia.claseID && reserva.horarioID === instancia.horarioID && reserva.fechaYMD === instancia.fechaYMD)
					reservasYaExistentes.push(reserva)
			}

			const reservasQueUsanCupoMismoDia = _.filter(periodoDeCupos.reservas, r => r.utilizaCupo && dayjs(r.fechaInicio).isSame(instancia.fechaInicio, 'day'))

			const sabadoLibre = plan.sabadoLibre && dayjs(instancia.fechaYMD).isoWeekday() === 6

			const cuposDisponibles = calcularCuposDisponiblesPeriodoFecha({
				pago,
				plan,
				periodo: periodoDeCupos,
				fecha: dayjs()
			})

			// * Limites diarios
			const reservasDelDiaPorPrograma = _.reduce(instanciaProgramaIDs, (acum, programaID) => {
				const reservasMismoDiaYMismoPrograma = _.filter(reservasQueUsanCupoMismoDia, (i) => {
					const clase = gimnasio.clases[i.claseID]
					const suProgramaIDs = clase.programasIDs
					return suProgramaIDs.includes(programaID)
				})
				acum[programaID] = reservasMismoDiaYMismoPrograma.length
				return acum
			}, {} as Record<string, number>)

			const cantidadDeReservasMismoDia = _.size(reservasQueUsanCupoMismoDia)

			const maximoDiarioAlcanzado = limitarReservasDiariasPorMembresia
				? _.size(reservasQueUsanCupoMismoDia) >= plan.maximoReservasDiarias
				: !_.some(reservasDelDiaPorPrograma, (reservas) => {
					const maximo = plan.maximoReservasDiarias
					if (!maximo)
						return false
					return reservas < maximo
				})

			const puedeReservarPorReservas: boolean = !!(_.isEmpty(reservasYaExistentes) || plan.configuraciones?.multiplesReservasPorSesion)
			const puedeReservarPorCupoDia: boolean = !!(plan.cupoPorDia && cantidadDeReservasMismoDia)
			const puedeReservarPorCupos: boolean = !!(!maximoDiarioAlcanzado && (sabadoLibre || (cuposDisponibles > 0) || plan.cuposIlimitados))

			const elegible = puedeReservarPorReservas && (puedeReservarPorCupoDia || puedeReservarPorCupos)

			// * Final
			const posibilidad: PosibilidadDeReserva = {
				posibilidadID: `${membresia.membresiaID}>${pago.pagoID}`,
				membresiaID: membresia.membresiaID,
				pagoID: pago.pagoID,
				instanciaID: instancia.instanciaID,
				fechaInicio: instancia.fechaInicio,
				periodoDeCupos,

				reservaIDs: _.map(reservasYaExistentes, 'reservaID'),

				sabadoLibre,
				utilizaCupo: !sabadoLibre,
				cuposIlimitados: plan.cuposIlimitados,
				cuposDisponibles,

				maximoReservasDiarias: plan.maximoReservasDiarias,
				cantidadDeReservasMismoDia,
				cupoPorDia: plan.cupoPorDia,
				reservasDelDiaPorPrograma,
				maximoDiarioAlcanzado,

				puedeReservarPorCupoDia,
				puedeReservarPorCupos,
				puedeReservarPorReservas,
				elegible
			}

			posibilidades.push(posibilidad)
		}

		// Resultados
		return posibilidades
	}
	finally {
		// consolo.groupEnd()
	}
}

export function CalcularPosibilidadesDeReservaPorMembresias(datos: {
	gimnasio: Gimnasio
	instancia: InstanciaCalculada
	membresias: Record<string, Membresia>
}): PosibilidadDeReserva[] {
	const { gimnasio, instancia, membresias: membresiasConRelacionados } = datos
	if (_.isEmpty(membresiasConRelacionados))
		return []

	const posibilidadesDeReserva: PosibilidadDeReserva[] = []
	for (const membresia of _.values(membresiasConRelacionados)) {
		const posibilidades = CalcularPosibilidadesDeReservaConMembresia({ gimnasio, instancia, membresia })
		posibilidadesDeReserva.push(...posibilidades)
	}
	return posibilidadesDeReserva
}

export function RevisarSiUsaSalaYEligeUnLugarValido(datos: {
	gimnasio: Gimnasio
	instancia: Instancia
	lugarID?: string
}): { reservable: boolean; motivo?: string; lugarSugerido?: string } {
	const { gimnasio, instancia, lugarID } = datos

	const clase = gimnasio.clases[instancia.claseID]
	const horario = clase.horarios[instancia.horarioID]

	if (!horario.salaID) {
		if (lugarID) {
			return {
				reservable: false,
				motivo: 'No se puede elegir un lugar en una clase que no usa sala'
			}
		}
		return { reservable: true }
	}
	if (!lugarID) {
		return {
			reservable: false,
			motivo: 'Se debe elegir un lugar'
		}
	}

	const sala = gimnasio.salas[horario.salaID]
	if (!sala) {
		return {
			reservable: false,
			motivo: 'Sala no encontrada'
		}
	}

	const lugaresReservados = new Set<string>()
	for (const reserva of Object.values(instancia.reservas)) {
		if (reserva.lugarID) {
			lugaresReservados.add(reserva.lugarID)
		}
	}

	const lugaresDeLaSala = new Set<string>()
	const lugaresUtilizablesDeLaSala = new Set<string>()
	const lugaresDisponibles = new Set<string>()

	for (const columna of Object.values(sala.columnas)) {
		for (const [lugarID, elemento] of Object.entries(columna.elementos)) {
			lugaresDeLaSala.add(lugarID)
			if (elemento.estado === 'no_disponible')
				continue
			lugaresUtilizablesDeLaSala.add(lugarID)
			if (!lugaresReservados.has(lugarID))
				lugaresDisponibles.add(lugarID)
		}
	}

	if (!lugaresDeLaSala.has(lugarID)) {
		return {
			reservable: false,
			motivo: 'Lugar no encontrado'
		}
	}

	if (!lugaresUtilizablesDeLaSala.has(lugarID)) {
		return {
			reservable: false,
			motivo: 'Lugar no utilizable'
		}
	}

	if (!lugaresDisponibles.has(lugarID)) {
		return {
			reservable: false,
			motivo: 'Lugar no disponible'
		}
	}

	return {
		reservable: true
	}
}

export function BuscarLugarDisponible(datos: {
	gimnasio: Gimnasio
	instancia: Instancia
}): string | undefined {
	const { gimnasio, instancia } = datos

	const clase = gimnasio.clases[instancia.claseID]
	const horario = clase.horarios[instancia.horarioID]

	if (!horario.salaID)
		return undefined

	const sala = gimnasio.salas[horario.salaID]
	if (!sala)
		return undefined

	const lugaresReservados = new Set<string>()
	for (const reserva of Object.values(instancia.reservas)) {
		if (reserva.lugarID) {
			lugaresReservados.add(reserva.lugarID)
		}
	}

	const lugaresDeLaSala = new Set<string>()
	const lugaresUtilizablesDeLaSala = new Set<string>()
	const lugaresDisponibles = new Set<string>()

	for (const columna of Object.values(sala.columnas)) {
		for (const [lugarID, elemento] of Object.entries(columna.elementos)) {
			lugaresDeLaSala.add(lugarID)
			if (elemento.estado === 'no_disponible')
				continue
			lugaresUtilizablesDeLaSala.add(lugarID)
			if (!lugaresReservados.has(lugarID))
				lugaresDisponibles.add(lugarID)
		}
	}
	return lugaresDisponibles.values().next().value
}

export function DeterminarReservabilidadPorTiemposDeInstancia(datos: {
	gimnasio: Gimnasio
	instanciaInicioDate: Date
	instanciaFinDate: Date
}): Reservabilidad {
	const { gimnasio, instanciaInicioDate, instanciaFinDate } = datos
	const instanciaInicio = dayjs(instanciaInicioDate)
	const instanciaFin = dayjs(instanciaFinDate)

	// Revisar que la reserva sea antes de que termine la clase
	const ahora = dayjs()

	if (instanciaFin.isBefore(ahora)) {
		return {
			reservable: false,
			motivo: 'sesionConcluida'
		}
	}

	// Hora de apertura
	const minsParaApertura = gimnasio.restricciones.reservaAperturaMins
	// console.log('minsParaApertura', minsParaApertura)
	const limitarAperturaDeReservas = typeof minsParaApertura === 'number'
	if (limitarAperturaDeReservas) {
		// * minsParaApertura Puede ser 0
		const limiteApertura = instanciaInicio.subtract(minsParaApertura, 'm')

		// console.log('limiteApertura', limiteApertura?.format('h:mma'))

		if (ahora.isBefore(limiteApertura)) {
			return {
				reservable: false,
				motivo: 'limiteApertura'
			}
		}
	}

	// Antelacion minima
	const minsDeAntelacion = gimnasio.restricciones.reservaAntelacionMins
	const limiteAntelacion
		= minsDeAntelacion && instanciaInicio.subtract(minsDeAntelacion, 'm')
	if (minsDeAntelacion && ahora.isAfter(limiteAntelacion)) {
		return {
			reservable: false,
			motivo: 'minsDeAntelacion'
		}
	}

	// Minutos reservables tras inicio
	const minsReservablesTrasInicio = gimnasio.restricciones.reservaTrasInicioMins
	if (minsReservablesTrasInicio) {
		const limiteReservablesTrasInicio = dayjs(instanciaInicio).add(minsReservablesTrasInicio, 'm')

		if (ahora.isAfter(limiteReservablesTrasInicio)) {
			return {
				reservable: false,
				motivo: 'reservaTrasInicioMins'
			}
		}
	}

	return {
		reservable: true
	}
}
