import { z } from 'zod'
import { BooleanOFalsoZod, BooleanOpcionalZod, NumeroZod, StringONull, StringOpcionalZod, StringZod, URLOpcionalZod } from './zodComun'

const dispositivos = ['ios', 'web', 'android'] as const
export const DispositivoZod = z.enum(dispositivos)
export type Dispositivo = z.infer<typeof DispositivoZod>

export const ModoZod = z.enum(['dev', 'debug', 'produccion', 'tunel'])

export type Modo = z.infer<typeof ModoZod>

export const AppListadaZod = z.object({
	appID: StringZod,
	nombre: StringZod,
	descripcion: StringZod,

	hosts: z.array(StringZod),
	androidLink: StringOpcionalZod,
	iosLink: StringOpcionalZod,
	gimnasioBMIDs: z.array(NumeroZod),

	logo: StringOpcionalZod,
	logoDark: StringOpcionalZod,

	deprecar: BooleanOpcionalZod
}) 

export type AppListada = z.infer<typeof AppListadaZod>

export const ListadoDeAppsZod = z.record(z.string(), AppListadaZod)
export type ListadoDeApps = z.infer<typeof ListadoDeAppsZod>

export const TextosDeAppZod = z.object({ fraseInicio: StringZod }).optional()

export const AppConfigFrontZod = z.object({
	appID: StringZod,
	nombre: StringZod,
	descripcion: StringZod,
	
	splashFondo: StringZod,
	splashFondoOscuro: StringZod,

	hosts: z.array(StringZod),
	androidLink: StringOpcionalZod,
	iosLink: StringOpcionalZod,

	bundleID: StringZod,


	tema: z.enum(['claro', 'oscuro']).optional(), 
	gTagManager: StringONull,

	textos: TextosDeAppZod,
	
	preferencias: z.object({
		requiereAutorizacion: BooleanOFalsoZod
	}).optional(),

	permisosDeAnonimo: z.object({
		crearCuenta: BooleanOpcionalZod,
		ingresar: BooleanOpcionalZod,
		cambiarPass: BooleanOpcionalZod
	}).optional(),

	cloudflareTurnstileSiteKey: StringOpcionalZod,

	// * Llave de firma publica de servidor
	firmaLlavePublica: StringZod,
	// firmaLlavePrivada: StringZod,
})
export type AppConfigFront = z.infer<typeof AppConfigFrontZod>
