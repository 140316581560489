import _ from 'lodash'
import { ErrorAumentado } from '@base/lib/error'
import { z } from 'zod'


import { NoticiaZod, RegistroDeNoticiasZod } from '@comun/types'
import type { EdicionDeNoticia, Noticia, RegistroDeNoticias } from '@comun/types'
import { ErrorNotificableZod, i18nIconos, i18nTapi } from '../lib/erroresNotificables'

const consoloRaiz = 'Lib TAPI Noticias'
const consoloColor = 'color: Khaki'
// Creación, Edición y carga de imágenes
const creandoNoticiaRef = ref<boolean | null>(null)
export const creandoNoticia = computed<boolean>(() => creandoNoticiaRef.value === true)

async function CrearNoticia(): Promise<Noticia> {
	const fx = 'CrearNoticia'
	consolo.group(`%c${consoloRaiz} ${fx}`, consoloColor)
	try {
		creandoNoticiaRef.value = true
		const headers = HeadersConAuth()
		const respuesta = await axiosWorker({
			url: `${contextoApp.buildConfig.apiUrl}/boxmagic/noticias`,
			method: 'post',
			headers
		})
			

		const parser = z
			.discriminatedUnion('ok', [
				z.object({
					ok: z.literal(true),
					noticia: NoticiaZod
				}),
				z.object({
					ok: z.literal(false),
					error: ErrorNotificableZod
				})
			])
		const parseo = parser.safeParse(respuesta)

		if (!parseo.success)
			throw new ErrorAumentado('malParseo', { origen: fx, error: parseo.error })

		const resultado = parseo.data
		if (!resultado.ok) {
			consolo.error('error', resultado.error, resultado)
			throw new ErrorAumentado(resultado.error, { origen: fx, error: resultado.error })
		}
		const noticia = resultado.noticia
		return noticia
	}
	catch (e) {
		if (e instanceof ErrorAumentado)
			throw e.trazar(fx)
		consolo.error('error', e)
		throw new ErrorAumentado(fx, { error: e })
	}
	finally {
		creandoNoticiaRef.value = false
		ObtenerNoticias()
		consolo.groupEnd()
	}
}

async function ObtenerURLDeCargaDeImagen(noticiaID: string): Promise<{
	get: string
	put: string
}> {
	const fx = 'ObtenerURLDeCargaDeImagen'
	consolo.group(`%c${consoloRaiz} ${fx}`, consoloColor)
	try {
		const headers = HeadersConAuth()
		const respuesta = await axiosWorker({
			url: `${contextoApp.buildConfig.apiUrl}/boxmagic/noticias/${noticiaID}/putimagenurl`,
			method: 'get',
			headers
		})
			

		const parser = z
			.discriminatedUnion('ok', [
				z.object({
					ok: z.literal(true),
					get: URLZod,
					put: URLZod
				}),
				z.object({
					ok: z.literal(false),
					error: ErrorNotificableZod
				})
			])
		const parseo = parser.safeParse(respuesta)

		if (!parseo.success)
			throw new ErrorAumentado('malParseo', { origen: fx, error: parseo.error })

		const resultado = parseo.data
		if (!resultado.ok) {
			notificadorEnApp.atencion({
				titulo: i18nTapi('falloElIngreso'),
				texto: i18nTapi(resultado.error),
				codigo: resultado.error,
				icono: i18nIconos[resultado.error]
			})

			consolo.log('error', resultado.error, resultado)
			throw new ErrorAumentado(resultado.error, { error: resultado.error })
		}

		return _.pick(resultado, ['get', 'put'])
	}
	catch (e) {
		if (e instanceof ErrorAumentado)
			throw e.trazar(fx)
		consolo.error('error', e)
		throw new ErrorAumentado(fx, { error: e })
	}
	finally {
		consolo.groupEnd()
	}
}

const editandoNoticiaRef = ref<boolean | null>(null)
const editandoNoticia = computed<boolean>(() => editandoNoticiaRef.value === true)

async function EditarNoticia(noticiaID: string, edicionNoticia: EdicionDeNoticia): Promise<Noticia> {
	const fx = 'EditarNoticia'
	consolo.group(`%c${consoloRaiz} ${fx}`, consoloColor)
	try {
		editandoNoticiaRef.value = true
		const headers = HeadersConAuth()
		const respuesta = await axiosWorker({
			url: `${contextoApp.buildConfig.apiUrl}/boxmagic/noticias/${noticiaID}`,
			method: 'put',
			headers,
			data: edicionNoticia
		})
			

		const parser = z
			.discriminatedUnion('ok', [
				z.object({
					ok: z.literal(true),
					noticia: NoticiaZod
				}),
				z.object({
					ok: z.literal(false),
					error: ErrorNotificableZod
				})
			])
		const parseo = parser.safeParse(respuesta)

		if (!parseo.success)
			throw new ErrorAumentado('malParseo', { origen: fx, error: parseo.error })

		const resultado = parseo.data
		if (!resultado.ok) {
			consolo.log('error', resultado.error, resultado)
			throw new ErrorAumentado(resultado.error, { error: resultado.error, origen: fx })
		}

		const traducido = TrosettaAPI.crear({
			noticiaGuardada: {
				es: 'Noticia guardada',
				en: 'News saved',
				pt: 'Notícia salva'
			},
			noticiaPublicada: {
				es: 'Noticia publicada',
				en: 'News published',
				pt: 'Notícia publicada'
			},
			imagenGuardada: {
				es: 'Imagen guardada',
				en: 'Image saved',
				pt: 'Imagem salva'
			}
		})

		const imagenGuardada = !!edicionNoticia.imagen
		const noticiaPublicada = edicionNoticia.estado === 'publicada'

		notificadorEnApp.exito({
			icono: imagenGuardada ? 'solar:gallery-send-bold' : noticiaPublicada ? 'solar:plate-bold' : 'solar:cloud-check-bold-duotone',
			titulo: imagenGuardada ? traducido('imagenGuardada') : noticiaPublicada ? traducido('noticiaPublicada') : traducido('noticiaGuardada'),
			texto: '',
			duracionSegundos: 3
		})

		const noticia = resultado.noticia
		return noticia
	}
	catch (e) {
		if (e instanceof ErrorAumentado)
			throw e.trazar(fx)
		consolo.error('error', e)
		throw new ErrorAumentado(fx, { error: e })
	}
	finally {
		editandoNoticiaRef.value = false
		ObtenerNoticias()
		consolo.groupEnd()
	}
}

// Solo lectura

const noticiasRef = ref<RegistroDeNoticias | null | false>(null)

const noticias = computed((): Noticia[] => {
	const ns = unref(noticiasRef)
	if (!ns)
		return []

	const lista = _.orderBy(ns, ['fechaPublicacion', 'creacion'], ['desc'])
	return lista
})

const noticiasVisibles = computed((): Noticia[] => {
	const ns = unref(noticias)
	if (!ns)
		return []

	const publicadas = _.filter(ns, n => n.estado === 'publicada')

	const visibles = _.filter(publicadas, (noticia) => {
		const ahora = unref(reloj)
		if (!noticia.fechaPublicacion)
			return false
		if (ahora.isBefore(noticia.fechaPublicacion))
			return false
		if (noticia.fechaArchivacion && ahora.isAfter(noticia.fechaArchivacion))
			return false
		return true
	})
	return visibles
})

const noticiasPublicadas = computed((): Noticia[] => {
	const ns = unref(noticias)
	if (!ns)
		return []

	const publicadas = _.filter(ns, n => n.estado === 'publicada')

	const visibles = _.filter(publicadas, (noticia) => {
		const ahora = unref(reloj)
		if (!noticia.fechaPublicacion)
			return false

		if (noticia.fechaArchivacion && ahora.isAfter(noticia.fechaArchivacion))
			return false

		return true
	})
	return visibles
})

const noticiasArchivadas = computed((): Noticia[] => {
	const ns = unref(noticias)
	if (!ns)
		return []
	const filtradas = _.filter(ns, (noticia) => {
		const ahora = unref(reloj)
		if (noticia.fechaArchivacion && ahora.isAfter(noticia.fechaArchivacion))
			return true
		if (noticia.estado === 'archivada')
			return true
		return false
	})
	return filtradas
})

const refrescandoNoticiasRef = ref<boolean | null>(null)
const refrescandoNoticias = computed(() => refrescandoNoticiasRef.value)

async function ObtenerNoticias(): Promise<boolean> {
	const fx = 'ObtenerNoticias'
	consolo.group(`%c${consoloRaiz} ${fx}`, consoloColor)
	try {
		const headers = HeadersConAuth()
		if (!headers['GoTs-Gimnasio']) {
			consolo.log('abortado por falta de gimnasioID')
			return false
		}
		refrescandoNoticiasRef.value = true
		const respuesta = await axiosWorker({
			url: `${contextoApp.buildConfig.apiUrl}/boxmagic/noticias`,
			method: 'get',
			headers
		})
			

		const parser = z
			.discriminatedUnion('ok', [
				z.object({
					ok: z.literal(true),
					noticias: RegistroDeNoticiasZod
				}),
				z.object({
					ok: z.literal(false),
					error: ErrorNotificableZod
				})
			])
		const parseo = parser.safeParse(respuesta)

		if (!parseo.success)
			throw new ErrorAumentado('malParseo', { origen: fx, error: parseo.error })

		const resultado = parseo.data
		if (!resultado.ok) {
			consolo.log('error', resultado.error, resultado)
			noticiasRef.value = false
			throw new ErrorAumentado(resultado.error, { error: resultado.error, origen: fx })
		}

		const noticias = resultado.noticias
		noticiasRef.value = noticias

		return true
	}
	catch (e) {
		if (e instanceof ErrorAumentado)
			throw e.trazar(fx)
		consolo.error(`${fx}: error`, e)
		throw new ErrorAumentado(fx, { error: e })
	}
	finally {
		refrescandoNoticiasRef.value = false
		consolo.groupEnd()
	}
}

function Limpiar() {
	noticiasRef.value = null
}

export const NoticiasAPI = {
	CrearNoticia,
	ObtenerURLDeCargaDeImagen,
	EditarNoticia,
	ObtenerNoticias,
	Limpiar
}

export function useNoticias() {
	return {
		refrescandoNoticias,
		noticias,
		noticiasVisibles,
		noticiasPublicadas,
		noticiasArchivadas,

		creandoNoticia,
		editandoNoticia
	}
}
