import _ from 'lodash'
import { z } from 'zod'


import rosetta from '@base/lib/rosetta'
import emisorEventosObjeto from '@base/lib/emisorEventosObjeto'
import { ErrorAumentado } from '@base/lib/error'
import type { GimnasioListado } from '@comun/types'

import { GimnasioListadoZod } from '@comun/types'
import { crearStorage } from '~/lib/localforage'
import { ErrorNotificableZod, i18nIconos, i18nTapi } from '~/lib/erroresNotificables'



export function useApp() {
	return {
		obteniendoInfo
	}
}

const consoloRaiz = 'Lib TAPI App'
const consoloColor = 'color: yellow'

export const enCliente = import.meta.client

// * Eventos
const emisorEventos = { ...emisorEventosObjeto }

// * Storage
const AppStorage = crearStorage('AppGold')

export type GimnasioID = GimnasioListado['gimnasioID']

// * Estados
const sedesAppGoldRef = ref<Record<string, GimnasioListado> | null | false>(null)
const gimnasioIDRef = ref<GimnasioID | null | false>(null)

export const sedesGold = computed(() => sedesAppGoldRef.value)
export const gimnasioID = computed(() => gimnasioIDRef.value)


async function EnrolarEnGimnasio(gimnasioID: GimnasioID): Promise<boolean> {
	const fx = 'EnrolarEnGimnasio'
	consolo.group(`%c${consoloRaiz} ${fx}`, consoloColor)
	try {
		consolo.log(fx, gimnasioID)
		if (esAppGold)
			VerificarSiEsSede(gimnasioID)
		const respuesta = await axiosWorker({
			url: `${contextoApp.buildConfig.apiUrl}/boxmagic/gimnasios/inscribir`,
			method: 'post',
			headers: HeadersConAuth(),
			data: {
				gimnasioID
			}
		})
			

		const respuestaInscripcionOk = z.object({
			ok: z.literal(true)
		})
		const respuestaInscripcionGimError = z.object({
			ok: z.literal(false),
			error: ErrorNotificableZod
		})
		const ParserInscripcion = z.discriminatedUnion('ok', [
			respuestaInscripcionOk,
			respuestaInscripcionGimError
		])

		const parseoInscripcionGim = ParserInscripcion.safeParse(respuesta)

		if (!parseoInscripcionGim.success) {
			consolo.error(
				`Error al inscribirse en el gimnasio ${gimnasioID}.`,
				parseoInscripcionGim.error
			)
			return false
		}

		const traducido = rosetta({
			inscripcionRealizada: {
				es: 'Inscripción realizada',
				en: 'Enrollment completed',
				pt: 'Inscrição realizada'
			},
			registroRealizado: {
				es: 'Registro realizado',
				en: 'Registration completed',
				pt: 'Registro realizado'
			}
		})

		if (!parseoInscripcionGim.data.ok) {
			const errorID = parseoInscripcionGim.data.error
			notificadorEnApp.atencion({
				titulo: i18nTapi('falloEnrolacion'),
				texto: i18nTapi(errorID),
				codigo: errorID,
				icono: i18nIconos[errorID]
			})
			return false
		}

		notificadorEnApp.exito({
			titulo: traducido('registroRealizado'),
			texto: '',
			icono: 'carbon:identification'
		})

		consolo.log(fx, 'gimnasioIDRef.value = ', gimnasioID)
		gimnasioIDRef.value = gimnasioID
		emisorEventos.emit('gimnasioID', gimnasioID)
		AppStorage.setItem<GimnasioID>('gimnasioSeleccionado', gimnasioID)
		await AppStorage.setItem<GimnasioID>('gimnasioSeleccionado', gimnasioID)

		const u = unref(usuario)
		if (!u)
			return true
		consolo.log(`%c ${fx}`, 'color: magenta; font-size: 20px;')

		PerfilEnGimnasioAPI.ObtenerPerfilEnGimnasio()
		// Esperar un requestAnimationFrame
		await proximoFrame()

		return true
	}
	catch (e) {
		if (e instanceof ErrorAumentado)
			throw e.trazar(fx)
		consolo.error(fx, e)
		gimnasioIDRef.value = false
		// return false
		throw new ErrorAumentado(fx, { error: e })
	}
	finally {
		consolo.groupEnd()
	}
}

function VerificarSiEsSede(gimnasioID: GimnasioID): boolean {
	const fx = 'VerificarSiEsSede'
	consolo.group(`%c${consoloRaiz} ${fx}`, consoloColor)
	try {
		if (esAppGold) {
			const sedes = unref(sedesGold)
			if (sedes === false || !sedes) {
				consolo.error('No se ha cargado las sedes de la app gold.')
				return false
			}

			const sede = sedes[gimnasioID]
			if (!sede) {
				consolo.error('gimnasioID no es de sede')
				return false
			}
		}
		return true
	}
	catch (e) {
		consolo.error(fx, e)
		throw new ErrorAumentado(fx, { error: e })
	}
	finally {
		consolo.groupEnd()
	}
}

async function RecuperarSeleccionDeGimnasio(): Promise<string | undefined> {
	const fx = 'RecuperarSeleccionDeGimnasio'
	if (!unref(usuario)) return
	consolo.group(`%c${consoloRaiz} ${fx}`, consoloColor)
	const gimnasioID = await AppStorage.getItem<GimnasioID>('gimnasioSeleccionado')
	if (!gimnasioID) return

	await SeleccionarGimnasio(gimnasioID)
	return gimnasioID
}

async function SeleccionarGimnasio(gimnasioID: GimnasioID, forzar = false): Promise<boolean> {
	const fx = 'SeleccionarGimnasio'
	try {
		if (!forzar && gimnasioIDRef.value === gimnasioID) {
			consolo.log('Ya se ha seleccionado este gimnasio.')
			return true
		}
		consolo.group(`%c${consoloRaiz} ${fx}`, consoloColor)
		if (esAppGold && !VerificarSiEsSede(gimnasioID))
			return false

		consolo.log(fx, 'gimnasioIDRef.value = ', gimnasioID)
		gimnasioIDRef.value = gimnasioID

		emisorEventos.emit('gimnasioID', gimnasioID)
		await AppStorage.setItem<GimnasioID>('gimnasioSeleccionado', gimnasioID)

		const peg = unref(perfilEnGimnasio)
		if (!peg || peg.gimnasioID !== gimnasioID) await PerfilEnGimnasioAPI.ObtenerPerfilEnGimnasio()

		return true
	}
	catch (error) {
		consolo.error(error)
		gimnasioIDRef.value = false
		return false
	}
	finally {
		consolo.groupEnd()
	}
}

function autoSeleccionarGimnasioUnico() {
	consolo.log('\n\nautoSeleccionarGimnasioUnico')
	const sedes = unref(sedesGold)
	if (sedes && _.size(sedes) === 1)
		return SeleccionarGimnasio(Object.keys(sedes)[0])
}

async function DesconectarGimnasio() {
	const fx = 'DesconectarGimnasio'
	consolo.group(`%c${consoloRaiz} ${fx}`, consoloColor)
	try {
		await AppStorage.clear()
		AppStorage.removeItem('gimnasioSeleccionado')
		gimnasioIDRef.value = false

		autoSeleccionarGimnasioUnico()
	}
	catch (e) {
		if (e instanceof ErrorAumentado)
			throw e.trazar(fx)
		consolo.error(fx, e)
		throw new ErrorAumentado(fx, { error: e })
	}
	finally {
		consolo.groupEnd()
	}
}

const puestaEnMarchaRef = ref<boolean | null>(null)
const inicializandoAppRef = ref(false)

export const puestaEnMarcha = computed(() => puestaEnMarchaRef.value)
export const inicializandoApp = computed(() => inicializandoAppRef.value)

async function init() {
	const fx = 'App init'
	try {
		if (inicializandoAppRef.value) {
			consolo.warn('Ya se está inicializando la app')
			return
		}
		inicializandoAppRef.value = true

		await Promise.all([
			TemaOscuroAPI.init(),
			UsuarioAPI.Refrescar(),
			(sedesAppGoldRef.value === null) ? await ObtenerInfo() : null,
			NotificacionesAPI.init()
		])

		puestaEnMarchaRef.value = true
	}
	catch (error) {
		consolo.error(fx, error)
		puestaEnMarchaRef.value = false
	}
	finally {
		inicializandoAppRef.value = false
	}
}


export const AppZod = z.object({
	nombre: z.string(),
	textos: z
		.object({
			fraseInicio: z.string()
		})
		.optional(),

	gimnasioIDs: z.array(z.string()).optional(),
	permisosDeAnonimo: z.object({
		crearCuenta: BooleanOpcionalZod,
		ingresar: BooleanOpcionalZod,
		cambiarPass: BooleanOpcionalZod
	}).optional()
})

export type App = z.infer<typeof AppZod>

const obteniendoInfoRef = ref(false)
export const obteniendoInfo = computed(() => obteniendoInfoRef.value)

const RespuestaInfoAppZod = z.object({
	ok: z.literal(true),
	app: AppZod,
	gimnasios: z.array(GimnasioListadoZod).optional()
})
const RespuestaInfoAppError = z.object({
	ok: z.literal(false),
	error: ErrorNotificableZod
})
const ParserInfoApp = z.discriminatedUnion('ok', [
	RespuestaInfoAppZod,
	RespuestaInfoAppError
])

async function ObtenerInfo(): Promise<boolean> {
	const fx = 'ObtenerInfo'
	// consolo.group(`%c${consoloRaiz} ${fx}`, consoloColor)
	try {
		if (obteniendoInfoRef.value) {
			consolo.warn('Ya se está obteniendo la info de la app')
			return false
		}
		obteniendoInfoRef.value = true
		const respuesta = await axiosWorker({
			url: `${contextoApp.buildConfig.apiUrl}/boxmagic/app/info`,
			method: 'get',
			headers: HeadersBase()
		})
			

		consolo.log(`${fx} respuesta`, respuesta)

		const parseoInfoApp = ParserInfoApp.safeParse(respuesta)
		if (!parseoInfoApp.success) {
			// Reportar
			throw new ErrorAumentado('ErrorDeParseo', {
				datos: { parser: 'parseoInfoApp' },
				error: parseoInfoApp.error
			})
			// return false
		}

		if (parseoInfoApp.data.ok === false) {
			// const errorID = parseoInfoApp.data.error

			// notificadorEnApp.atencion({
			// 	titulo: i18nTapi('appDesactivada'),
			// 	texto: i18nTapi(errorID),
			// 	codigo: errorID,
			// 	icono: i18nIconos[errorID]
			// })

			return false
		}

		if (!_.isEmpty(parseoInfoApp.data.gimnasios)) {
			const registroDeGimnasiosIntegrado: Record<string, GimnasioListado> = {}
			_.forEach(parseoInfoApp.data.gimnasios, (gimnasio) => {
				registroDeGimnasiosIntegrado[gimnasio.gimnasioID] = gimnasio
			})

			sedesAppGoldRef.value = registroDeGimnasiosIntegrado
			if (_.size(registroDeGimnasiosIntegrado) === 1)
				autoSeleccionarGimnasioUnico()
		}
		return true
	}
	catch (e) {
		DesconectarGimnasio()
		if (e instanceof ErrorAumentado)
			throw e.trazar(fx)
		consolo.error(fx, e)
		throw new ErrorAumentado(fx, { error: e })
	}
	finally {
		obteniendoInfoRef.value = false
		AppAPI.emit('inicializada')
	}
}

export const AppAPI = {
	...emisorEventos,
	init,
	RecuperarSeleccionDeGimnasio,
	EnrolarEnGimnasio,
	SeleccionarGimnasio,
	DesconectarGimnasio
}

