
type ErrData = {
	message?: string;
	stack?: string;
	info?: string;
	component?: string;
}

async function ReportarError(err: unknown, errorData: ErrData) {
	// Enviar al backend

	console.error('Error', err, errorData);

	fetch(`${contextoApp.buildConfig.apiUrl}/err/reporte`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(errorData),
	}).catch((err) => console.error('Failed to report error', err));
}


export default defineNuxtPlugin((nuxtApp) => {
	nuxtApp.vueApp.config.errorHandler = (error: unknown, vm, info) => {
		// Verificar si el error es de tipo Error
		const errorData: ErrData = {
			message: (error instanceof Error) ? error.message : String(error),
			stack: (error instanceof Error) ? error.stack : '',
			info,
			component: vm?.$options?.name || 'Anonymous',
		};

		// Enviar al backend
		ReportarError(error, errorData)
	};

	if (!import.meta.client) return;
	window.onerror = (message, source, lineno, colno, error: unknown) => {
		const errorData: ErrData = {
			message: String(message),
			stack: '',
			info: `${source}:${lineno}:${colno}`,
			component: 'Anonymous',
		}
		ReportarError(error, errorData);
	};
});
