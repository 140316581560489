/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Comlink from 'comlink';
import type { AxiosRequestConfig } from 'axios';

// Cargar el Web Worker compilado desde el servidor
const workerUrl = '/axiosWorker.js';

let worker: Worker | null = null;
let api: any = null;

// Inicializar el Worker
const initWorker = () => {
	if (!worker) {
		worker = new Worker(workerUrl);
		api = Comlink.wrap(worker);
	}
};

// Función para hacer la solicitud HTTP usando `axios(config)`
const requestInWorker = async (config: AxiosRequestConfig) => {
	initWorker();

	try {
		const response = await api.request(config); // Pasar toda la configuración al Worker

		// Validar la respuesta
		if (response.success === false) {
			console.error('response.success === false');
			console.error('Ocurrió un error, response.success === false, response.error:', response.error);
			throw response;  // Lanza el error serializado
		}
		return response;
	} catch (error: any) {
		// El error será el que viene serializado desde el Worker
		console.error('Ocurrió un error notado por axiosWorker:', error);
		// Pasar el error directamente al manejador
		throw error;  // Re-lanzar el error después de manejarlo
	}
};

// API principal similar a Axios
export const axiosWorker = (config: AxiosRequestConfig) => {
	return requestInWorker(config)
		.then(r => r.data)
		.catch(ManejadorErroresAxios);
};
