import { z } from 'zod'
import RosettasATraductor from '@base/lib/rosetta'
import type { ColeccionDeTraducciones, MicRosetta as Rosetta } from '@base/lib/rosetta'

interface ErrorNotificable {
	icono: string
	traducciones: Rosetta
}
type RegistroDeErroresNotificables = Record<string, ErrorNotificable>

// erroresComunes Errores comunes
export const erroresNotificables: RegistroDeErroresNotificables = {
	ErrorInesperado: {
		icono: 'solar:cloud-bolt-bold-duotone',
		traducciones: {
			es: 'Error inesperado',
			en: 'Unexpected error',
			pt: 'Erro inesperado'
		}
	},
	falloObtencionDePerfilEnGimnasio: {
		icono: 'solar:card-2-broken',
		traducciones: {
			es: 'Fallo la obtención de perfil en gimnasio',
			en: 'Failed to obtain gym profile',
			pt: 'Falha ao obter perfil na academia'
		}
	},
	noExisteConfigPrincipal: {
		icono: 'solar:clound-cross-bold-duotone',
		traducciones: {
			es: 'Configuración principal no encontrada',
			en: 'Main configuration not found',
			pt: 'Configuração principal não encontrada'
		}
	},
	appNoAutorizada: {
		icono: 'octicon:no-entry-24',
		traducciones: {
			es: 'App no autorizada',
			en: 'App not authorized',
			pt: 'App não autorizado'
		}
	},
	gimNoAutorizado: {
		icono: 'octicon:no-entry-24',
		traducciones: {
			es: 'Gimnasio no autorizado en esta app',
			en: 'Gym not authorized in this app',
			pt: 'Academia não autorizada neste app'
		}
	},
	boxNoEncontrado: {
		icono: 'tabler:home-question',
		traducciones: {
			es: 'Box no encontrado',
			en: 'Box not found',
			pt: 'Box não encontrado'
		}
	},
	maximoDiarioAlcanzado: {
		icono: 'solar:battery-low-line-duotone',
		traducciones: {
			es: 'Máximo diario alcanzado',
			en: 'Daily maximum reached',
			pt: 'Máximo diário atingido'
		}
	},
	gimnasioNoEncontrado: {
		icono: 'tabler:home-question',
		traducciones: {
			es: 'Gimnasio no encontrado',
			en: 'Gym not found',
			pt: 'Ginásio não encontrado'
		}
	},
	usuarioNoEncontrado: {
		icono: 'solar:magnifer-broken',
		traducciones: {
			es: 'Usuario no encontrado',
			en: 'User not found',
			pt: 'Usuário não encontrado'
		}
	},
	emailNoRegistrado: {
		icono: 'solar:mailbox-bold-duotone',
		traducciones: {
			es: 'Email no registrado',
			en: 'Email not registered',
			pt: 'Email não registrado'
		}
	},
	emailYaRegistrado: {
		icono: 'fluent:person-lock-20-regular',
		traducciones: {
			es: 'Email ya registrado, puedes intentar ingresar con tu contraseña o configurar una nueva si no la recuerdas',
			en: 'Email already registered, you can try to login with your password or set a new one if you don\'t remember it',
			pt: 'Email já registrado, você pode tentar fazer login com sua senha ou definir uma nova se não se lembrar dela'
		}
	},
	passwordNoExiste: {
		icono: 'fluent:shield-question-32-filled',
		traducciones: {
			es: 'Password no existe',
			en: 'Password does not exist',
			pt: 'Senha não existe'
		}
	},
	passwordIncorrecto: {
		icono: 'fluent:shield-keyhole-24-filled',
		traducciones: {
			es: 'Password incorrecto',
			en: 'Incorrect password',
			pt: 'Senha incorreta'
		}
	},
	emailNoUtilizable: {
		icono: 'solar:mailbox-broken',
		traducciones: {
			es: 'Email no utilizable',
			en: 'Email not usable',
			pt: 'Email não utilizável'
		}
	},
	tokenExpirado: {
		icono: 'fluent:settings-chat-24-regular',
		traducciones: {
			es: 'Tu sesión ha expirado',
			en: 'Your session has expired',
			pt: 'Sua sessão expirou'
		}
	},
	tokenInvalido: {
		icono: 'solar:shield-bold-duotone',
		traducciones: {
			es: 'Token de sesión inválido',
			en: 'Invalid session token',
			pt: 'Token de sessão inválido'
		}
	},
	conexionFallidaDB: {
		icono: 'fluent:database-plug-connected-20-regular',
		traducciones: {
			es: 'DB desconectada',
			en: 'DB disconnected',
			pt: 'DB desconectada'
		}
	},
	conexionRechazadaSDB: {
		icono: 'solar:bolt-broken',
		traducciones: {
			es: 'Conexión rechazada por el servidor de base de datos',
			en: 'Connection rejected by the database server',
			pt: 'Conexão rejeitada pelo servidor de banco de dados'
		}
	},
	conexionFallidaSDB: {
		icono: 'solar:bolt-broken',
		traducciones: {
			es: 'Falló la conexión con el servidor de base de datos',
			en: 'Connection to the database server failed',
			pt: 'Falha na conexão com o servidor de banco de dados'
		}
	},
	appDesactivada: {
		icono: 'solar:bolt-broken',
		traducciones: {
			es: 'App desactivada',
			en: 'App deactivated',
			pt: 'App desativado'
		}
	},
	falloElIngreso: {
		icono: 'carbon:retry-failed',
		traducciones: {
			es: 'Falló el ingreso',
			en: 'Login failed',
			pt: 'Falha no login'
		}
	},
	falloEnrolacion: {
		icono: 'solar:danger-circle-bold-duotone',
		traducciones: {
			es: 'Falló la enrolación',
			en: 'Enrollment failed',
			pt: 'Falha na inscrição'
		}
	},
	falloObtencionDeHorarios: {
		icono: 'solar:calendar-broken',
		traducciones: {
			es: 'Fallo la obtención de horarios',
			en: 'Failed to obtain schedules',
			pt: 'Falha ao obter horários'
		}
	},
	noSePudoCargarMembresias: {
		icono: 'solar:cloud-storm-bold-duotone',
		traducciones: {
			es: 'No se pudo cargar los planes adquiridos',
			en: 'Could not load purchased plans',
			pt: 'Não foi possível carregar os planos adquiridos'
		}
	},
	noPuedeOperarEnEsteGimnasio: {
		icono: 'emojione-monotone:no-entry',
		traducciones: {
			es: 'Esta cuenta no puede operar en este gimnasio',
			en: 'This account cannot operate in this gym',
			pt: 'Esta conta não pode operar nessa academia'
		}
	},
	noPuedeOperarEnCentrosDeEstaApp: {
		icono: 'emojione-monotone:no-entry',
		traducciones: {
			es: 'Esta cuenta no puede operar en centros de esta app',
			en: 'This account cannot operate in centers of this app',
			pt: 'Esta conta não pode operar em centros deste app'
		}
	},
	esComplicado: {
		icono: 'nimbus:exclamation-triangle',
		traducciones: {
			es: 'Es complicado',
			en: 'It is complicated',
			pt: 'É complicado'
		}
	},

	falloLaCargaDePerfil: {
		icono: 'uil:user-exclamation',
		traducciones: {
			es: 'Falló la carga de perfil',
			en: 'Profile loading failed',
			pt: 'Falha no carregamento do perfil'
		}
	},
	falloLaEdicionDePerfil: {
		icono: 'uil:user-exclamation',
		traducciones: {
			es: 'Falló la edición de perfil',
			en: 'Profile editing failed',
			pt: 'Falha na edição do perfil'
		}
	},
	plataformaEnMantencion: {
		icono: 'icon-park-twotone:worker',
		traducciones: {
			es: 'Plataforma en mantención',
			en: 'Platform in maintenance',
			pt: 'Plataforma em manutenção'
		}
	},
	noSePudoSolicitarCambioDePassword: {
		icono: 'ph:password-duotone',
		traducciones: {
			es: 'No se pudo solicitar el cambio de contraseña',
			en: 'The password change request could not be made',
			pt: 'Não foi possível solicitar a alteração de senha'
		}
	},
	noExisteUnaCuentaConEsteEmail: {
		icono: 'xxx',
		traducciones: {
			es: 'No existe una cuenta con este email',
			en: 'There is no account with this email',
			pt: 'Não existe uma conta com este email'
		}
	},
	peroPuedesCrearUna: {
		icono: 'xxx',
		traducciones: {
			es: 'pero puedes crear una',
			en: 'but you can create one',
			pt: 'mas você pode criar uma'
		}
	},

	falloObtencionToken: {
		icono: 'xxx',
		traducciones: {
			es: 'Fallo la obtención del token',
			en: 'Failed to get token',
			pt: 'Falha ao obter o token'
		}
	},
	noExisteUsuarioID: {
		icono: 'xxx',
		traducciones: {
			es: 'No existe el usuario',
			en: 'User does not exist',
			pt: 'Usuário não existe'
		}
	},
	falloLaEliminacionDeLaCuenta: {
		icono: 'xxx',
		traducciones: {
			es: 'Falló la eliminación de la cuenta',
			en: 'Failed to delete the account',
			pt: 'Falha ao excluir a conta'
		}
	},

	codigoInvalido: {
		icono: 'iconoir:password-error',
		traducciones: {
			es: 'El código no es válido',
			en: 'The code is not valid',
			pt: 'O código não é válido'
		}
	},
	cambioDePasswordFallido: {
		icono: 'mdi:security-close',
		traducciones: {
			es: 'Cambio de password fallido',
			en: 'Password change failed',
			pt: 'Falha na alteração de senha'
		}
	},
	operacionNoSolicitada: {
		icono: 'material-symbols:detection-and-zone',
		traducciones: {
			es: 'Operación no solicitada',
			en: 'Operation not requested',
			pt: 'Operação não solicitada'
		}
	},
	sinPerfilEnGimnasio: {
		icono: 'mdi:account-question',
		traducciones: {
			es: 'Sin perfil en gimnasio',
			en: 'No profile in gym',
			pt: 'Sem perfil na academia'
		}
	},
	noTieneMembresiasCompatibles: {
		icono: 'solar:bookmark-square-minimalistic-outline',
		traducciones: {
			es: 'No tienes planes compatibles',
			en: 'You do not have compatible plans',
			pt: 'Você não tem planos compatíveis'
		}
	},
	limiteDiasFuturosReserva: {
		icono: 'solar:calendar-mark-broken',
		traducciones: {
			es: 'No es posible agendar con tanta anticipación',
			en: 'It is not possible to schedule so far in advance',
			pt: 'Não é possível agendar com tanta antecedência'
		}
	},
	todosLosCuposUtilizados: {
		icono: 'solar:bookmark-square-minimalistic-outline',
		traducciones: {
			es: 'Todos los cupos utilizados',
			en: 'All tokens used',
			pt: 'Todas as cotas utilizadas'
		}
	},
	sistemaNoLoPermite: {
		icono: 'solar:danger-outline',
		traducciones: {
			es: 'El sistema no lo permite',
			en: 'The system does not allow it',
			pt: 'O sistema não permite'
		}
	},
	laSesionAunNoHaIniciado: {
		icono: 'solar:minus-circle-bold-duotone',
		traducciones: {
			es: 'La sesión aún no ha iniciado',
			en: 'The session has not yet started',
			pt: 'A sessão ainda não começou'
		}
	},
	sesionConcluida: {
		icono: 'solar:hourglass-broken',
		traducciones: {
			es: 'Sesión concluida',
			en: 'Session concluded',
			pt: 'Sessão concluída'
		}
	},
	capacidadCompleta: {
		icono: 'solar:users-group-two-rounded-bold-duotone',
		traducciones: {
			es: 'Capacidad completa',
			en: 'Full capacity',
			pt: 'Capacidade completa'
		}
	},
	sesionCompleta: {
		icono: 'solar:users-group-two-rounded-bold-duotone',
		traducciones: {
			es: 'Sesión completa',
			en: 'Session full',
			pt: 'Sessão completa'
		}
	},
	listaDeEsperaCompleta: {
		icono: 'solar:users-group-two-rounded-bold-duotone',
		traducciones: {
			es: 'Lista de espera completa',
			en: 'Waiting list full',
			pt: 'Lista de espera completa'
		}
	},
	limiteApertura: {
		icono: 'solar:watch-round-line-duotone',
		traducciones: {
			es: 'El período de reserva de esta sesión aún no ha comenzado',
			en: 'The reservation period for this session has not yet started',
			pt: 'O período de reserva para esta sessão ainda não começou'
		}
	},
	minsDeAntelacion: {
		icono: 'solar:watch-round-broken',
		traducciones: {
			es: 'El periodo de reserva de esta sesión ha terminado',
			en: 'The reservation period for this session has ended',
			pt: 'O período de reserva para esta sessão terminou'
		}
	},
	reservaTrasInicioMins: {
		icono: 'solar:watch-round-broken',
		traducciones: {
			es: 'El periodo de reserva tras el inicio de esta sesión ha terminado',
			en: 'The reservation period after the start of this session has ended',
			pt: 'O período de reserva após o início desta sessão terminou'
		}
	},
	instanciaNoReservable: {
		icono: 'solar:danger-line-duotone',
		traducciones: {
			es: 'Esta instancia no es reservable',
			en: 'This instance is not reservable',
			pt: 'Esta instância não é reservável'
		}
	},

	congelado: {
		icono: 'solar:snowflake-bold',
		traducciones: {
			es: 'Perfil congelado',
			en: 'Frozen profile',
			pt: 'Perfil congelado'
		}
	},
	perfilCongelado: {
		icono: 'solar:snowflake-bold',
		traducciones: {
			es: 'Perfil congelado',
			en: 'Frozen profile',
			pt: 'Perfil congelado'
		}
	},
	cuentaEliminada: {
		icono: 'solar:user-block-line-duotone',
		traducciones: {
			es: 'Cuenta eliminada',
			en: 'Account deleted',
			pt: 'Conta excluída'
		}
	}
} as const

// Extraer las claves utilizando keyof typeof
export type ErroresNotificablesIDs = keyof typeof erroresNotificables

export const ErrorNotificableZod = z.custom<ErroresNotificablesIDs>((val) => {
	if (typeof val !== 'string') {
		console.error('ErrorNotificableZod: val no es string, val:', val)
		return false
	}
	const errorRegistrado = Object.hasOwnProperty.call(erroresNotificables, val)
	if (!errorRegistrado) {
		console.error('ErrorNotificableZod: error no registrado, val:', val)
		return false
	}
	return true
})

const iconosDeTraducciones: Record<string, string> = {}
const coleccionDeRosettas: ColeccionDeTraducciones = {}

for (const [codigoError, { traducciones, icono }] of Object.entries(
	erroresNotificables
)) {
	coleccionDeRosettas[codigoError] = traducciones
	iconosDeTraducciones[codigoError] = icono
}

export const i18nIconos = iconosDeTraducciones
export const i18nTapi = RosettasATraductor(coleccionDeRosettas)
