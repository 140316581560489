import dayjs from 'dayjs'
export type { Dayjs } from 'dayjs'

import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import isoWeek from 'dayjs/plugin/isoWeek'
import isBetween from 'dayjs/plugin/isBetween'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import objectSupport from 'dayjs/plugin/objectSupport'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isoWeek)
dayjs.extend(isBetween)
dayjs.extend(customParseFormat)
dayjs.extend(objectSupport)

dayjs.valueOf = function (this: dayjs.Dayjs) {
	return this.toDate().valueOf()
}


export default dayjs
