import { z } from 'zod'
import dayjs from './fechas'
import { Dayjs } from './fechas'

function URLConCatch(puedeSerUrl: string) {
	try {
		return z.string().url().parse(puedeSerUrl)
	}
	catch (e) {
		// console.error('Esto tenia que ser una url:', puedeSerUrl)
		// console.error(e)
		return undefined
	}
}

export const IndexZod = z.preprocess(
	v => ((typeof v === 'string' && v !== 'null' && v !== 'undefined') ? v : undefined),
	z.string().min(1)
)

export const IDTextualZod = z.preprocess(v => (v ? String(v) : undefined), z.string())
export const IDTextualOpcionalZod = z.preprocess(v => (v ? String(v) : undefined), z.string().optional())
export const IDNumericoOpcionalZod = z.preprocess(
	v => ((v && !Number.isNaN(Number(v)) && v !== 0) ? Number(v) : undefined),
	z.number().optional()
)

function preprocesadorNumero(v: any): number | undefined {
	if (typeof v === 'number' && !Number.isNaN(Number(v)))
		return v
	if (typeof v === 'string' && !Number.isNaN(Number(v)))
		Number(v)
	return undefined
}

export const IDNumericoZod = z.preprocess((v) => {
	const numeroOIndefinido = preprocesadorNumero(v)
	return (numeroOIndefinido && numeroOIndefinido !== 0) ? numeroOIndefinido : undefined
}, z.number())

export const NumeroZod = z.preprocess(v => preprocesadorNumero(v), z.number())
export const NumeroOpcionalZod = z.preprocess(v => preprocesadorNumero(v), z.number().optional())
export const NumeroONullZod = z.preprocess(v => preprocesadorNumero(v) ?? null, z.number().nullable())
export const NumeroOCeroZod = z.preprocess(v => preprocesadorNumero(v) ?? 0, z.number())

export const StringZod = z.preprocess((v) => {
	if (typeof v === 'string')
		return v
	return undefined
}, z.string())
export const StringOpcionalZod = z.preprocess((v) => {
	if (typeof v === 'string')
		return v
	return undefined
}, z.string().optional()).catch(undefined)
export const StringONull = z.preprocess(v => v ?? null, z.string().nullable())

// export const URLONada = z.preprocess(v => (typeof v === 'string' && URLConCatch(v)) || undefined, z.string().url())

export const URLZod = z.preprocess(
	v => (typeof v === 'string' && URLConCatch(v.trim())) || undefined,
	z.string().url()
)

export const URLOpcionalZod = z.preprocess(
	v => (typeof v === 'string' && URLConCatch(v.trim())) || undefined,
	z.string().url().optional()
)

export const BooleanOFalsoZod = z.preprocess(v => !!(v ?? false), z.boolean())
export const BooleanOVerdaderoZod = z.preprocess(v => !!(v ?? true), z.boolean())
export const BooleanOpcionalZod = z.preprocess(v => (typeof v === 'undefined' ? undefined : !!v), z.boolean().optional())

export const MontoZod = z.number().min(0).max(999999999)
export const MontoPositivoZod = z.number().min(1).max(999999999)

export const MonedasZod = z.enum(['CLP', 'USD', 'MXN', 'EUR', 'COP', 'CRC', 'PEN', 'BRL', 'BOB', 'HNL', 'ARS'])
export type Monedas = z.infer<typeof MonedasZod>

export const PrecioOpcionalZod = z.object({ monto: z.number(), moneda: z.preprocess(v => v === 'ARG' ? 'ARS' : v, MonedasZod) }).optional().catch(undefined)
// type PrecioOpcional = z.infer<typeof PrecioOpcionalZod>

function preprocesadorFecha(v: any): Date | undefined {
	if (v === undefined || v === null)
		return undefined
	
	if (v instanceof Date) {
		if (!Number.isNaN(v.getTime()))
			return v
	}

	// * Si es objeto dayjs
	if (dayjs.isDayjs(v) && typeof v.toDate === 'function')
		return v.toDate()

	if (typeof v.$d !== 'undefined' && v.$d instanceof Date)
		return v.$d

	if (typeof v === 'string') {
		const fecha = new Date(v)
		if (fecha instanceof Date) {
			if (!Number.isNaN(fecha.getTime()))
				return fecha
			console.warn('preprocesadorFecha, es string pero no logra ser parseado', typeof v, v)
		}
	}

	// console.log('preprocesadorFecha, es OTRO', typeof v, v)
	return v
}

export const FechaZod = z.preprocess(v => preprocesadorFecha(v), z.date())

function preprocesadorFechaYMD(v: any): string | undefined {
	// const fecha = preprocesadorFecha(v)
	// return fecha ? fecha.toISOString().split('T')[0] : undefined
	if (typeof v !== 'string')
		return preprocesadorFecha(v)?.toISOString().split('T')[0]
	const validable = dayjs(v, 'YYYY-MM-DD')
	return validable.isValid() ? v : undefined
}

export const FechaYMDZod = z.preprocess(preprocesadorFechaYMD, z.string())
export const FechaYMDOpcional = z.preprocess(preprocesadorFechaYMD, z.string().optional())

export const FechaOpcionalZod = z.preprocess(preprocesadorFecha, z.date().optional())
export const FechaONullZod = z.preprocess(v => preprocesadorFecha(v) || null, z.date().nullable())
export const FechaOAhoraZod = z.preprocess(v => preprocesadorFecha(v) || new Date(), z.date())

function PreprocesadorDayjs(v: any) {
	if (dayjs.isDayjs(v))
		return v
	if (typeof v === 'string')
		return dayjs(v)
	if (typeof v === 'object')
		return dayjs(v)
	return null
}

export const FechaADayjsZod = z
	.preprocess(PreprocesadorDayjs, z.custom<Dayjs>())

function preprocesadorFechaDMY(v: any): Date | undefined {
	if (v === undefined || v === null)
		return undefined
	if (typeof v !== 'string')
		return undefined
	return dayjs(v, 'DD/MM/YYYY').toDate()
}
export const DMYaFechaZod = z.preprocess(preprocesadorFechaDMY, z.date())
export const FechaDMYAISOZod = DMYaFechaZod.transform(v => dayjs(v).toISOString())

export const FechaADayjsOpcionalZod = z
	.preprocess(v => preprocesadorFecha(v) || new Date(), z.date().optional())
	.transform(v => v ? dayjs(v) : undefined)

export const FechaAISOZod = z
	.preprocess(v => preprocesadorFecha(v) || new Date(), z.date())
	.transform(v => dayjs(v).toISOString())

export const FechaAYMDZod = z
	.preprocess(v => preprocesadorFecha(v) || new Date(), z.date())
	.transform(v => dayjs(v).format('YYYY-MM-DD'))

export function EsFecha(puedeSer: any) {
	const revision = FechaZod.safeParse(puedeSer)
	return revision.success
}

const horaRegex = /^(0\d|1\d|2[0-3]):[0-5]\d$/
export const HoraZod = z.string().regex(horaRegex)

export const DiaSemanaZod = z.number().min(1).max(7)

export const ArrayCualquieraONada = z.preprocess(v => v ?? undefined, z.array(z.any()))

export function EsArray(puedeSer: any) {
	const revision = ArrayCualquieraONada.safeParse(puedeSer)
	return revision.success
}

function EmailConCatch(puedeSerEmail: string) {
	try {
		return z.string().email().parse(puedeSerEmail)
	}
	catch (e) {
		console.error('Esto tenia que ser un email:', `"${puedeSerEmail}"`)
		// console.error(e)
		// throw new ErrorAumentado('emailInvalido', { error: e })
		return null
	}
}

export const EmailZod = z.preprocess(
	v => (typeof v === 'string' && EmailConCatch(v.trim().toLowerCase())) || undefined,
	z.string().email()
)
export const EmailOpcionalZod = z.preprocess(
	v => (typeof v === 'string' && EmailConCatch(v.trim().toLowerCase())) || undefined,
	z.string().email().optional()
)

export const PassZod = z.string()
export const PassNuevoZod = z.string().min(6)
