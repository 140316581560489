import { FechaZod } from './fechas.types'
import { z } from 'zod'

export const NotificacionAumentadaZod = z.object({
	usuarioID: z.string(),
	id: z.string(),

	titulo: z.string(),
	texto: z.string(),
	icono: z.string().optional(),

	fechaEnQueMostrar: FechaZod, // 
	urlQueAbrir: z.string().optional(),

	data: z.any().optional()
})
export type NotificacionAumentada = z.infer<typeof NotificacionAumentadaZod>

export const RegistroDeNotificacionesAumentadasZod = z.record(z.string(), NotificacionAumentadaZod)
export type RegistroDeNotificacionesAumentadas = z.infer<typeof RegistroDeNotificacionesAumentadasZod>




export const PaqueteDesdeAppZod = z.discriminatedUnion('accion', [
	z.object({ accion: z.literal('recibirMensajesPendientes') }),
	z.object({ accion: z.literal('recibirNotificacionesPendientes') }),
	z.object({ accion: z.literal('cancelarTodas'), }),
	z.object({
		accion: z.literal('programarNotificacion'),
		notification: NotificacionAumentadaZod
	})
])
export type PaqueteDesdeApp = z.infer<typeof PaqueteDesdeAppZod>


export const PaqueteHaciaAppZod = z.discriminatedUnion('accion', [
	z.object({
		accion: z.literal('notiClick'),
		url: z.string().optional(),
		data: z.any()
	}),
	z.object({
		accion: z.literal('notiClose'),
		url: z.string().optional(),
		data: z.any()
	}),
	z.object({
		accion: z.literal('notificacionRecibida'),
		url: z.string().optional(),
		data: z.any()
	}),
	z.object({
		accion: z.literal('mensajeSSE'),
		url: z.string().optional(),
		data: z.any()
	}),
])
export type PaqueteHaciaApp = z.infer<typeof PaqueteHaciaAppZod>
