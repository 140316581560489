import { contextoApp } from './contextoApp'

const { log, error } = console
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function loqsea(..._args: unknown[]) { }

export const consolo = unref(contextoApp)?.buildConfig.dev
	? console
	: {
		...console,
		trulog: log,
		info: loqsea,
		log: loqsea,
		time: loqsea,
		timeEnd: loqsea,
		group: loqsea,
		groupEnd: loqsea,
		error
	}
